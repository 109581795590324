import {useState} from 'react';
// MUI
import {Grid, Container, FormControl, FormControlLabel, Switch} from '@mui/material';
// components
import {Page} from "../../layouts/components";
import {
    HorizontalTable,
    HorizontalTableRow, SideDrawer
} from '../../components';
import ForecastConfigBar from "./ForecastConfigBar";
// Store
import {useAppSelector} from '../../store/store'
// Data
import {
    investmentReportColumn,
    investmentReportSelector
} from "../../store/capitalBudget/selectors/Reports/investmentReport";
import SummaryDrilldown from "../../sections/Budget/DrillDowns/Summary";


// Capital Budget Summary App Page
export default function CapitalBudgetApp() {
    const dataLoading = useAppSelector(state => state.capitalBudget.loading);

    const [avaCapToggle, setAvaCapToggle] = useState<boolean>(true);

    const [column, selectCol] = useState<investmentReportColumn | null>(null);
    const [row, selectRow] = useState<HorizontalTableRow | null>(null)

    const {
        data,
        capitalRows,
        analyticsRows
    } = useAppSelector(state => investmentReportSelector(state, avaCapToggle));


    return (
        <>
            <Page title="Metrics - Capital Budget">
                <Container maxWidth={false} sx={{maxWidth: '100%', height: 1, display: 'flex', flexDirection: 'row'}}>
                    <Grid container direction='column' sx={{height: 1, flex: 1}}>
                        <Grid container direction='row' justifyContent='space-between'>
                            <ForecastConfigBar title='Portfolio Summary'/>
                        </Grid>
                        <Grid item sx={{flex: 1, overflowY: 'hidden', width: '100%', py: 1}}>
                            <HorizontalTable
                                rows={[
                                    ...capitalRows,
                                    {
                                        id: 'avalCapToggle',
                                        label: '',
                                        headSX: {
                                            bgcolor: 'info.lighter',
                                            align: 'left',
                                            p: 0,
                                            color: 'black',
                                            fontWeight: 'bold',
                                            borderColor: 'secondary.light',
                                            '&:hover': {
                                                color: 'white',
                                                backgroundColor: 'secondary.light',
                                                borderColor: 'secondary.light'
                                            },
                                        },
                                        sx: {bgcolor: 'info.lighter', fontWeight: 'bold'},
                                        renderHeaderCell: () => (
                                            <>
                                                <FormControl>
                                                    <FormControlLabel control={
                                                        <Switch checked={avaCapToggle}
                                                                onChange={() => setAvaCapToggle(!avaCapToggle)}/>
                                                    } label={'Include Available Capital'}/>
                                                </FormControl>
                                            </>
                                        ),
                                    },
                                    ...analyticsRows
                                ]}
                                data={(data) ? data : []}
                                loading={dataLoading}

                                selectedRow={row}
                                selectedCol={column}
                                rowSelect={(row) => {
                                    // DO NOT SHOW ROW FOR THESE FOLLOWING ROWS
                                    if ((row && row.id && !['commitmentsHeader', 'divider', 'avalCapToggle'].includes(row.id)) || !row) selectRow(row)
                                }}
                                colSelect={(col) => selectCol(col as investmentReportColumn)}

                                selectableCell
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Page>
            {row && column &&
                <SideDrawer
                    title={summaryDrilldownTitle(row, column)}
                    open={!!row && !!column}
                    onClose={() => {
                        selectRow(null);
                        selectCol(null);
                    }}
                    size="x-large"
                >
                    <SummaryDrilldown row={row} column={column} data={data} avaCapToggle={avaCapToggle}/>
                </SideDrawer>
            }
        </>
    )
}

function summaryDrilldownTitle(row: HorizontalTableRow, column: investmentReportColumn) {
    if (['Senior (%)', 'Subordinated (%)', 'Equity (%)', 'Other (%)', 'Available Capital (%)'].includes(row.label)) {
        return `Debt Seniority - ${column.labelTwo}`
    }
    if (['Weighted Average Margin (%)', 'Base Rate (%)', 'Drawn Rate (%)', 'Weighted Average Interest (yrs)'].includes(row.label)) {
        return `Rates - ${column.labelTwo}`
    }
    if (['Weighted Average Rating', 'Weighted Average Rating', 'Investment Grade (%)', 'Sub-Investment Grade (%)'].includes(row.label)) {
        return `Credit Rating - ${column.labelTwo}`
    }
    if (['# Assets', 'Average Asset Size ($)', 'Largest Exposure ($)', 'Largest Exposure (%)', 'Top 10 Exposure ($)', 'Top 10 Exposure (%)'].includes(row.label)) {
        return `Assets - ${column.labelTwo}`
    }

    if (row.id === 'exposure' || /^sector/.test(row.id as string) || /^group/.test(row.id as string) || /^industry/.test(row.id as string)) {
        return `Industry Exposure - ${column.labelTwo}`
    }

    return `${row.label} - ${column.labelTwo}`
}