import {memo, ReactNode} from "react";
import {DataTable, valueDate} from "../../../../components";
import {GridColDef} from "@mui/x-data-grid";
import {generalRow} from "../../../../components/DataTable/DataTableUtils";
import {CalculationLoanType, ForecastPeriod} from "../../../../types/capitalBudgetTypes";
import {LoanSummaryFilter} from "./SummaryDrilldownsUtils";
import {FundDetails} from "../../../../store/capitalBudget/selectors/generalSelectors";

interface LoansDrilldownTableProps {
    title: string,
    period: ForecastPeriod,
    fund: FundDetails | null,
    additionalColumns?: Array<GridColDef>
    filterFunction?: (loan: CalculationLoanType) => boolean
    customFilterComponent?: ReactNode
    showLookThrough?: boolean
}

// Formats Loan data into table
function LoansDrilldownTable({
                                 title,
                                 period,
                                 fund,
                                 additionalColumns,
                                 filterFunction,
                                 customFilterComponent,
                                 showLookThrough = true
                             }: LoansDrilldownTableProps) {

    const {loans, lookthrough} = LoanSummaryFilter(period, fund, filterFunction, showLookThrough)

    return (
        <>
            <DataTable
                title={title}
                columns={[
                    {field: 'trancheId', headerName: 'Tranche ID', width: 100, ...generalRow},
                    ...(lookthrough) ? [
                        {
                            field: 'fund',
                            headerName: 'Inherited',
                            width: 90,
                            sortable: false,
                        }
                    ] : [],
                    {
                        field: 'borrower',
                        headerName: 'Borrower',
                        flex: 1,
                        minWidth: 200,
                        ...generalRow
                    },
                    {
                        field: 'tranche',
                        headerName: 'Tranche',
                        minWidth: 150,
                        flex: 0.5,
                        ...generalRow
                    },
                    {
                        field: 'maturity',
                        headerName: 'Maturity',
                        ...valueDate,
                        width: 100,
                    },
                    ...(additionalColumns || [])
                ]}
                data={loans}
                loading={false}
                showExport={false}
                search
                sort={{field: 'maturity', sort: 'asc'}}
                customFilterComponent={customFilterComponent}
            />
        </>
    )
}

export default memo(LoansDrilldownTable);