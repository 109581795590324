import {CalculationLoanType} from "../../../types/capitalBudgetTypes";
import {AmendmentType, InvestmentType, LoanTags, PeriodType} from "../../../types/capitalBudgetEnums";
import {Period} from "../../../types/GeneralTypes";
import {
    checkDateBefore,
    checkDateSame,
    checkDateSameOrBefore,
    checkInPeriod,
    daysBetweenDates,
    findlastDayOfMonth,
    findNextDayOfTheWeek
} from "../../../utils/DateUtils";
import {addValues, roundToDecimal} from "../../../utils/mathUtil";

/**
 * Handles existing loans
 * @param loan
 * @param budgetDate
 * @param period
 * @param periodType
 * @param periodMultiplier
 * @param ctcWeekBuffer
 * @param periodTypeMultiplier
 * @param base
 */
function handlePortfolioLoan(loan: CalculationLoanType, budgetDate: number | Date, period: Period, periodType: PeriodType, periodMultiplier: number, ctcWeekBuffer: number, periodTypeMultiplier: number, base: boolean = false) {
    /**
     * DETERMINE IF LOAN MEETS CERTAIN CRITERIA
     * - IN PERIOD
     * - IN PERIOD OF AMENDMENT
     *      - EXTENSION
     *      - EXTENSION OFFSET
     *      - EARLY REPAYMENT
     *      - EARLY REPAYMENT OFFSET
     *      - TRANSFERS -
     *      - TRANSFER OFFSETS -
     *          - NOTE HANDLING OF TRANSFERS IN AND OUT AND THE CREATION OF NEW LOANS -
     *
     * UPDATING THE FOLLOWING VALUES
     * - TENOR -
     * - DRAWN -
     * - UNDRAWN -
     * - CTC -
     */
    let updatedValue = loan.updatedValue;
    let updatedUndrawn = loan.updatedUndrawn;
    let updatedDrawn = loan.updatedDrawn;

    let updatedDrawnPercentage = loan.updatedDrawnPercentage;

    let updatedTenor = loan.updatedTenor;
    let ctcDrawdown = 0;
    let transferOutTotal = 0;
    let transferInTotal = 0;
    let selldownTotal = 0;
    let tags: Array<LoanTags> = [];

    // DETERMINE EARLY REPAYMENTS AND EXTENSIONS REPAYMENTS
    if (loan.amendedMaturity && checkInPeriod(loan.amendedMaturity, period)) {
        if (loan.maturityAmendmentType === AmendmentType.EARLY_REPAYMENT) tags.push(LoanTags.EARLY_REPAYMENT);
        if (loan.maturityAmendmentType === AmendmentType.EXTENSION) tags.push(LoanTags.EXTENSION);
    }

    // CONTRACTUAL REPAYMENTS
    if (checkInPeriod(loan.endDate, period)) {
        tags.push(LoanTags.REPAYMENT);
        if (loan.maturityAmendmentType === AmendmentType.EARLY_REPAYMENT) tags.push(LoanTags.OFFSET_EARLY);
        if (loan.maturityAmendmentType === AmendmentType.EXTENSION) tags.push(LoanTags.OFFSET_EXTENSION);
        // if (loan.transfersIn && loan.transfersIn.length > 0) tags.push(LoanTags.OFFSET_TRANSFER_IN);
        // if (loan.transfersOut && loan.transfersOut.length > 0) tags.push(LoanTags.OFFSET_TRANSFER_OUT);
    }

    // DETERMINE LOAN END DATE - BASE FORECAST DOES NOT TAKE INTO ACCOUNT AMENDED MATURITY
    const adjustedEndDate = (!base && loan.amendedMaturity) ? loan.amendedMaturity : loan.endDate;

    // HANDLING FORECAST FEATURES (SELLDOWNS, TRANSFERS)
    if (!base) {
        // HANDLE LOAN SELLDOWNS
        if (loan.selldowns && loan.selldowns.length > 0) {
            loan.selldowns.forEach(s => {
                // IF SELLDOWN IS IN PERIOD ADD TAG
                if (checkInPeriod(s.date, period)) {
                    if (!tags.includes(LoanTags.SELLDOWN)) {
                        tags.push(LoanTags.SELLDOWN);
                    }
                }
                // REDUCE LOAN VALUE OVER LENGTH OF LOAN
                if (checkDateSameOrBefore(s.date, period.lastDate)) {
                    const selldownReductionPer = 1 - (s.amount / updatedValue);

                    updatedValue = addValues(updatedValue, -s.amount);
                    updatedDrawn = roundToDecimal(updatedDrawn * selldownReductionPer);
                    updatedUndrawn = roundToDecimal(updatedUndrawn * selldownReductionPer);
                }
                // CHECK FOR SELLDOWN OFFSET WHEN LOAN ENDS
                if (checkInPeriod(adjustedEndDate, period)) {
                    if (!tags.includes(LoanTags.OFFSET_SELLDOWN)) {
                        tags.push(LoanTags.OFFSET_SELLDOWN);
                    }
                }
            })
        }

        // HANDLE TRANSFERS OUT
        if (loan.transfersOut && loan.transfersOut.length > 0) {
            loan.transfersOut.forEach(t => {
                // IF TRANSFER IS IN PERIOD ADD TAG
                if (checkInPeriod(t.transferDate, period)) {
                    if (!tags.includes(LoanTags.TRANSFER_OUT)) {
                        tags.push(LoanTags.TRANSFER_OUT);
                    }
                }
                // REDUCE LOAN VALUE OVER LENGTH OF LOAN
                if (checkDateSameOrBefore(t.transferDate, period.lastDate)) {
                    updatedValue = addValues(updatedValue, -t.amount);
                    updatedDrawn = roundToDecimal(updatedValue * updatedDrawnPercentage);
                    updatedUndrawn = roundToDecimal(updatedValue * (1 - updatedDrawnPercentage));
                }
                // CHECK FOR TRANSFER OFFSET WHEN LOAN ENDS
                if (checkInPeriod(adjustedEndDate, period)) {
                    if (!tags.includes(LoanTags.OFFSET_TRANSFER_OUT)) {
                        tags.push(LoanTags.OFFSET_TRANSFER_OUT);
                    }
                }
            })
        }

        // HANDLE TRANSFER IN - OF EXISTING
        if (loan.transfersIn && loan.transfersIn.length > 0) {
            loan.transfersIn.forEach(t => {
                // CHECK TRANSFER IS NOT FIRST TRANSFER INTO A NEW FUND
                if (!loan.transferInDate || (!checkDateSame(t.transferDate, loan.transferInDate))) {
                    // IF TRANSFER IS IN PERIOD ADD TAG
                    if (checkInPeriod(t.transferDate, period)) {
                        if (!tags.includes(LoanTags.TRANSFER_IN)) {
                            tags.push(LoanTags.TRANSFER_IN);
                        }
                    }
                    // REDUCE LOAN VALUE OVER LENGTH OF LOAN
                    if (checkDateSameOrBefore(t.transferDate, period.lastDate)) {
                        updatedValue = addValues(updatedValue, t.amount);
                        updatedDrawn = roundToDecimal(addValues(updatedDrawn, (t.amount * updatedDrawnPercentage)));
                        updatedUndrawn = roundToDecimal(addValues(updatedUndrawn, (t.amount * (1 - updatedDrawnPercentage))));
                    }
                    // CHECK TRANSFER IS THE FIRST TRANSFER INTO A NEW FUND
                } else if (loan.transferInDate && checkDateSame(t.transferDate, loan.transferInDate)) {
                    // IF TRANSFER IS IN PERIOD ADD TAG
                    if (checkInPeriod(t.transferDate, period)) {
                        if (!tags.includes(LoanTags.TRANSFER_IN)) {
                            tags.push(LoanTags.TRANSFER_IN);
                        }
                    }
                    // REDUCE LOAN VALUE OVER LENGTH OF LOAN
                    if (checkDateSameOrBefore(t.transferDate, period.lastDate)) {
                        updatedValue = t.amount;
                        updatedDrawn = roundToDecimal((t.amount * updatedDrawnPercentage));
                        updatedUndrawn = roundToDecimal((t.amount * (1 - updatedDrawnPercentage)));
                    } else if (loan.transferInDate && checkDateBefore(period.lastDate, loan.transferInDate)) {
                        updatedValue = 0;
                        updatedDrawn = 0
                        updatedUndrawn = 0;
                    }
                }
                // CHECK FOR TRANSFER OFFSET WHEN LOAN ENDS
                if (checkInPeriod(adjustedEndDate, period)) {
                    if (!tags.includes(LoanTags.OFFSET_TRANSFER_IN)) {
                        tags.push(LoanTags.OFFSET_TRANSFER_IN);
                    }
                }
            })
        }

    }

    // CHECK LOAN IS WITHIN PERIOD
    if (!checkDateBefore(adjustedEndDate, period.startDate)) {

        if ((base) || (!base && (!loan.transferInDate || (!!loan.transferInDate && checkDateSameOrBefore(loan.transferInDate, period.lastDate))))) {
            // ONLY SET ACTIVE IF LOAN END DATE IS BEFORE PERIOD END DATE EXCEPT FOR WHEN BASE CONTRACTUAL FORECAST
            if (base || checkDateBefore(period.lastDate, adjustedEndDate)) tags.push(LoanTags.ACTIVE);
        }

        // UPDATE TENOR
        updatedTenor = (daysBetweenDates(new Date(period.lastDate), new Date(adjustedEndDate)) / 365);
        if (updatedTenor < 0) {
            updatedTenor = 0;
        }

        /**
         * CRE CTC STRAIGHT LINE DRAWDOWN FOR:
         *  - INVESTMENT TYPE - REAL_ESTATE LOANS
         *  - TRANCHE TYPES THAT ARE NOT 'Revolving', 'Capex', or 'Equity'
         *  - UNDRAWN OR PARTIALLY DRAWN
         *
         *  THIS ALSO UPDATES:
         *  - updatedUndrawn
         *  - updatedDrawn
         *  - ctcDrawdown
         *
         */
        if (!base) {
            // CHECK INVESTMENT TYPE
            if (loan.investmentType === InvestmentType.REAL_ESTATE && ['Capex', 'Construction', 'Term Amortising', 'Term Bullet'].includes(loan.trancheType) && loan.drawnPercentage >= 0 && loan.drawnPercentage < 1) {
                // NUMBER OF PERIODS IN LOAN
                const endOfPeriod = (periodType === PeriodType.WEEK) ? findNextDayOfTheWeek(new Date(adjustedEndDate), 7) : findlastDayOfMonth((new Date(adjustedEndDate)));
                const numberOfPeriods = (periodType === PeriodType.WEEK) ?
                    Math.floor((daysBetweenDates(budgetDate, endOfPeriod) / 7) - ctcWeekBuffer)
                    :
                    Math.floor((daysBetweenDates(budgetDate, endOfPeriod) / 365) * periodTypeMultiplier - 1); // 1 MONTH BUFFER

                tags.push(LoanTags.CRE_CTC);
                // HANDLING OF UNDRAWN ACCESS LOANS IN THE FIRST PERIOD - NON-ACTIVE CRE LOANS
                if (loan.drawnPercentage === 0) {
                    tags.push(LoanTags.CRE_CTC_NON_ACTIVE);

                    if (periodMultiplier === 0) { // IF FIRST PERIOD DRAW HALF OF LOAN
                        if (checkDateBefore(budgetDate, adjustedEndDate)) {
                            const periodDrawdown = updatedUndrawn / 2;
                            updatedUndrawn = periodDrawdown;
                            ctcDrawdown = periodDrawdown;
                        } else {
                            ctcDrawdown = updatedValue;
                            updatedUndrawn = 0;
                        }
                    } else if ((numberOfPeriods - periodMultiplier) >= 1) { // AFTER FIRST PERIOD DIVIDE REMAINING HALF OVER REMAINING PERIODS
                        const periodCTC = (updatedUndrawn / 2) / (numberOfPeriods - 1);
                        ctcDrawdown = periodCTC;
                        // CALCULATE REMAINING UNDRAWN AMOUNT
                        updatedUndrawn = addValues((updatedUndrawn / 2), -(periodCTC * (periodMultiplier)));
                    }
                } else { // HANDLING OF EXISTING PARTIALLY DRAWN LOANS IN AXCESS - ACTIVE CRE LOANS
                    tags.push(LoanTags.CRE_CTC_ACTIVE);

                    if ((periodMultiplier === 0) && ((numberOfPeriods - periodMultiplier) < 1)) { // IF FIRST PERIOD AND REMAINING CTC PERIODS REMAINING IS LESS THAN 0 TAKE REMAINING UNDRAWN TO CTC
                        ctcDrawdown = updatedUndrawn;
                        updatedUndrawn = 0;
                    }
                    if (numberOfPeriods <= 0) {
                        updatedUndrawn = 0;
                    }
                    if (periodMultiplier >= 0 && ((numberOfPeriods - periodMultiplier) >= 1)) { // IF PERIOD IS 0 OR GREATER AND CTC PERIODS REMAINING IS GREATER OR EQUAL TO 1
                        // CALCULATE CTC PER PERIOD
                        const periodCTC = updatedUndrawn / numberOfPeriods;
                        ctcDrawdown = periodCTC;
                        // CALCULATE REMAIN UNDRAWN
                        updatedUndrawn = addValues(updatedUndrawn, -(periodCTC * (periodMultiplier + 1)));
                    }
                    if (periodMultiplier >= numberOfPeriods) {
                        updatedUndrawn = 0;
                    }
                }
            }
            // updatedDrawn = addValues(updatedValue, -updatedUndrawn);
            // updatedDrawnPercentage = updatedDrawn / updatedValue;
        }

    }

    updatedDrawn = addValues(updatedValue, -updatedUndrawn);
    updatedDrawnPercentage = updatedDrawn / updatedValue;

    return {
        ...loan,
        updatedValue,
        updatedDrawnPercentage,
        updatedUndrawn,
        updatedDrawn,
        updatedTenor,
        ctcDrawdown,
        transferInTotal,
        transferOutTotal,
        selldownTotal,
        tags
    }
}

export default handlePortfolioLoan;