import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {formatDate} from "../../../utils/DateUtils";
import {addValues} from "../../../utils/mathUtil";
import {Capital, MCPFund} from "../../../types/forecastTypes";
import {ForecastPeriod} from "../../../types/capitalBudgetTypes";
import {createFilteredPortfolioBook} from "../../../utils/CapitalBudgetUtils";
import {AccountBalance, BBSWRate} from "../../../types/externalDataTypes";

export const getCapitalBudgetFunds = createSelector(
    (state: RootState) => state.capitalBudget.forecastData?.funds || [],
    (funds) => funds.map(fund => fund.label)
)

export const getForecastExternalDataDates = createSelector(
    (state: RootState) => state.capitalBudget.externalData,
    (externalData) => {
        return {
            accountBalance: externalData?.accountBalance ? formatDate(externalData.accountBalance.date, 'dd-MMM-yyyy') : null,
            axcess: externalData?.portfolio ? formatDate(externalData.portfolio.date, 'dd-MMM-yyyy') : null,
            debtFacilities: externalData?.debtFacilities ? formatDate(externalData.debtFacilities.date, 'dd-MMM-yyyy') : null,
            nCino: externalData?.ncinoWip ? formatDate(externalData.ncinoWip.date, 'dd-MMM-yyyy') : null,
            holding: externalData?.holding ? formatDate(externalData.holding.date, 'dd-MMM-yyyy') : null,
            fx: externalData?.fx ? formatDate(externalData.fx.date, 'dd-MMM-yyyy') : null,
        }
    }
)

export const getForecastDate = (state: RootState) => state.forecast.forecast?.forecastDate || null;

// Selector to get the FX rates
export const ratesSelector = createSelector(
    (state: RootState) => state.capitalBudget.externalData?.fx?.rates || [],
    (rates) => {
        return rates
    }

)
// export const ratesSelector = (state: RootState) => state.capitalBudget.externalData?.fx?.rates || [];

// Selector to get the BBSW rates
export const retrieveBaseRate = createSelector(
    (state: RootState) => state.capitalBudget.externalData?.bbsw?.rates || [],
    (rates: Array<BBSWRate>) => {
        let baseRate = rates.find(r => r.tenor === 3);
        return baseRate ? baseRate.mid / 100 : null;
    }
)

// Selector to get the current fund
export const fundSelector = (state: RootState) => state.capitalBudget.misc.fund;


export interface FundDetails extends MCPFund {
    holdMap: Map<string, number> | null,
    inheritance: Map<string, number>
}

// Retrieves if Selected and holdings if applicable
export const fundHolderSelector = createSelector(
    (state: RootState) => state.capitalBudget.misc.fund,
    (state: RootState) => state.capitalBudget.externalData?.holding?.holding || [],
    (state: RootState) => state.capitalBudget.forecastData?.funds || [],
    (fund, holdings, funds): FundDetails | null => {
        if (!fund) return null;

        const holding = holdings.find(h => h.holder === fund.label);

        let holdMap;

        if (holding) {
            holdMap = holding.holdings.reduce((holdings, h) => {
                holdings.set(h.fund, h.percentage)

                return holdings;
            }, new Map())

        }

        // check funds for investment inheritance
        const inheritance = funds.reduce((inherit, f) => {
            if (f.allocations) {
                f.allocations.forEach(a => {
                    if (a.fund === fund.label) {
                        inherit.set(f.label, a.allocation)
                    }
                })
            }
            return inherit;
        }, new Map<string, number>())

        return {
            ...fund,
            holdMap,
            inheritance
        } as FundDetails;
    }
)

// Select all funds and details
export const fundsSelector = (state: RootState) => state.capitalBudget.forecastData?.funds || [];


// Select all funds and details
export const underlyingFundsSelector = createSelector(
    fundsSelector,
    (funds) => funds.filter(f => f.type === 'UNDERLYING'));

// Retrieves list of funds label only
export const fundsStringSelector = createSelector(
    (state: RootState) => state.capitalBudget.forecastData?.funds || [],
    (funds) => {
        return funds.map(f => f.label)
    }
)

// Selector for periods;
export const periodSelector = createSelector(
    (state: RootState) => state.capitalBudget.misc.weeks,
    (state: RootState) => state.capitalBudget.misc.months,
    (weeks, months) => ({weeks, months})
);

// Retrieves the cash and capital balance of selected fund.
export const initialBalance = createSelector(
    (state: RootState) => state.capitalBudget.externalData?.accountBalance?.balances || [],
    underlyingFundsSelector,
    fundHolderSelector,
    (balances, funds, selectedFund) => {
        if (selectedFund) {
            let cash = 0;
            let capitalAvailability = 0;

            if (selectedFund.holdMap) {
                for (let [fund, percentage] of selectedFund.holdMap) {
                    const balance = balances.find(b => b.fund === fund);
                    if (balance && balance.capitalAvailability > 0) {
                        capitalAvailability = addValues(capitalAvailability, balance.capitalAvailability * percentage);
                    }
                }
                const balance = balances.find(b => {
                    if (b.fund === selectedFund.label) return true;
                    else if (selectedFund.alternatives) {
                        return selectedFund.alternatives.find(a => a === b.fund)
                    } else return false;
                });
                if (balance) {
                    cash = balance.cash;
                }
            } else {
                const balance = balances.find(b => {
                    if (b.fund === selectedFund.label) return true;
                    else if (selectedFund.alternatives) {
                        return selectedFund.alternatives.find(a => a === b.fund)
                    } else return false;
                });
                if (balance) {
                    cash = balance.cash;
                    capitalAvailability = balance.capitalAvailability;
                }
            }

            return {
                cash,
                capitalAvailability
            }
        } else {
            let cash = 0;
            let capitalAvailability = 0;

            balances.forEach(b => {
                cash = addValues(cash, b.cash);
                let fund = funds.find(f => f.label === b.fund);
                if (!fund) {
                    for (let f of funds) {
                        if (f.alternatives && f.alternatives.includes(b.fund)) {
                            fund = f;
                            break;
                        }
                    }
                }
                if (fund && fund.type === 'UNDERLYING' && b.capitalAvailability > 0) {
                    capitalAvailability = addValues(capitalAvailability, b.capitalAvailability);
                }
            })

            return {
                cash,
                capitalAvailability
            }
        }
    }
)

// FUM selector
export const balancesSelector = createSelector(
    (state: RootState) => state.capitalBudget?.forecastData?.fum || [],
    (state: RootState) => state.capitalBudget.externalData?.accountBalance?.balances || [],
    (state: RootState) => state.capitalBudget?.forecastData?.funds || [],
    (fum, balances, funds) => {
        let fundsBalances: Array<{ fund: string, fum: number, cash: number, capital: number }> = [];

        let totals = {
            fum: 0,
            cash: 0,
            capital: 0
        }

        funds.forEach(fund => {
            let balance = {
                id: fund.id,
                fund: fund.label,
                fum: 0,
                cash: 0,
                capital: 0,
            };
            const f = fum.find(f => f.fund === fund.label);
            if (f) {
                balance.fum = f.amount;
                totals.fum = addValues(totals.fum, f.amount)
            }
            const cashCap = balances.find(f => f.fund === fund.label);
            if (cashCap) {
                balance.cash = cashCap.cash;
                balance.capital = cashCap.capitalAvailability;
                totals.cash = addValues(totals.cash, cashCap.cash)
                totals.capital = addValues(totals.capital, cashCap.capitalAvailability)
            } else if (fund.alternatives) {
                let alternativeCap: AccountBalance | null = null
                for (let alternate of fund.alternatives) {
                    alternativeCap = balances.find(b => b.fund === alternate) || null;
                    if (alternativeCap) {
                        balance.cash = addValues(balance.cash, alternativeCap.cash);
                        balance.capital = addValues(balance.capital, alternativeCap.capitalAvailability);
                        totals.cash = addValues(totals.cash, alternativeCap.cash);
                        totals.capital = addValues(totals.capital, alternativeCap.capitalAvailability);
                        break;
                    }
                }
            }
            fundsBalances.push(balance);
        })

        return {
            balances: fundsBalances,
            totals
        };
    }
)

// Retrieves FUM Balance for selected fund or total if no selected fund
export const fumBalanceSelector = createSelector(
    (state: RootState) => state.capitalBudget.forecastData?.fum || [],
    fundSelector,
    (fums, selectedFund) => {
        if (selectedFund) {
            const fund = fums.find(f => f.fund === selectedFund.label);
            return (fund) ? fund.amount : 0;
        } else {
            let total = 0;
            fums.forEach(f => {
                total = addValues(total, f.amount);
            })
            return total;
        }
    }
)

// Retrieves Budget Periods In Array
export const budgetPeriodArraySelector = createSelector(
    (state: RootState) => state.capitalBudget.capitalBudget?.budget,
    fundHolderSelector,
    (budget, fund) => {
        if (!budget) return [];

        const {
            base,
            weeks,
            months
        } = budget;

        return [
            handlePeriodSelect(base, fund),
            ...weeks.map(w => handlePeriodSelect(w, fund)),
            ...months.map(m => handlePeriodSelect(m, fund)),
        ]
    }
)

export function handlePeriodSelect(period: ForecastPeriod, fund: FundDetails | null): ForecastPeriod {
    return {
        ...period,
        book: createFilteredPortfolioBook(period.book, fund, false),
        capital: period.capital.reduce((filteredCapital: Array<Capital>, c) => {
            if (fund) {
                let amount = 0;
                if (fund.label === c.fund) {
                    amount = c.amount;
                } else {
                    const portion = fund.inheritance.get(c.fund);
                    if (portion) {
                        amount = c.amount * portion;
                    }
                }
                if (amount !== 0) {
                    filteredCapital.push({
                        ...c,
                        amount
                    })
                }
            } else {
                filteredCapital.push(c);
            }

            return filteredCapital;
        }, []),
        otherTransactions: period.otherTransactions.filter(ot => (!fund || (fund.label === ot.fund)))
    }
}