import {createSelector} from "@reduxjs/toolkit";
import _ from "lodash";
// Store
import {RootState} from "../../../store";
import {forecastCapitalColumn, forecastCapitalDataSelector} from "./forecastCapitalReport";
import {fundForecastColumn, fundForecastDataSelector} from "./fundForecastReport";
import {FundDetails, fundHolderSelector, retrieveBaseRate} from "../generalSelectors";
// Local imports
import {addArrayValues, addValues} from "../../../../utils/mathUtil";
import {fCurrency, fPercent} from "../../../../utils/formatNumber";
import {CellType} from "../../../../types/InputTypes";
import {ForecastPeriod} from "../../../../types/capitalBudgetTypes";
import {
    convertRatingToValue,
    convertValueToRating,
    createFilteredPortfolioBook
} from "../../../../utils/CapitalBudgetUtils";
// components
import {HorizontalTableColumn, HorizontalTableRow} from "../../../../components";
import {spIndustries} from "../../../../utils/spIndustryUtils";

const investmentReportCapitalRows: Array<HorizontalTableRow> = [
    {
        id: 'commitmentsHeader',
        label: 'Contractual Loan Commitments:',
        headSX: {minWidth: 250, bgcolor: 'primary.main', borderColor: 'primary.main'},
        sx: {bgcolor: 'primary.main', minWidth: 150}
    },
    {
        id: 'contractualDrawn',
        label: 'Contractual Drawn Commitment',
        formatter: fCurrency,
        headSX: {minWidth: 220},
        type: CellType.CURRENCY
    },
    {
        id: 'contractualUndrawn',
        label: 'Contractual Undrawn Commitment',
        formatter: fCurrency,
        headSX: {minWidth: 220},
        type: CellType.CURRENCY
    },
    {
        id: 'contractualTotalCommitment',
        label: 'Contractual Total Commitment (A)',
        formatter: fCurrency,
        type: CellType.CURRENCY,
        headSX: {
            bgcolor: 'info.lighter',
            color: 'black',
            fontWeight: 'bold',
            borderColor: 'secondary.light',
            '&:hover': {color: 'white', backgroundColor: 'secondary.light', borderColor: 'secondary.light'},
        },
        sx: {bgcolor: 'info.lighter', fontWeight: 'bold'}
    },
    {
        id: 'divider',
        label: '',
        headSX: {minWidth: 150, height: 15, bgcolor: 'common.white', borderColor: 'primary.main'},
        sx: {bgcolor: 'common.white'}
    },
    {
        id: 'contractualFum',
        label: 'Investors/Unit Capital (B)',
        formatter: fCurrency,
        headSX: {minWidth: 220},
        type: CellType.CURRENCY
    },
    {
        id: 'contractualFacilities',
        label: 'Bank Facilities (B)',
        formatter: fCurrency,
        headSX: {minWidth: 220},
        type: CellType.CURRENCY,
        subRows: [
            {
                id: 'contractualExternalFacilities',
                label: 'External Facilities',
                formatter: fCurrency,
                type: CellType.CURRENCY,
            },
            {
                id: 'contractualInternalFacilities',
                label: 'Internal Facilities',
                formatter: fCurrency,
                type: CellType.CURRENCY,
            }
        ]
    },
    {
        id: 'contractualCapitalAUM',
        label: 'Total AUM (\u03A3 of B)',
        formatter: fCurrency,
        type: CellType.CURRENCY,
        headSX: {
            bgcolor: 'info.lighter',
            color: 'black',
            fontWeight: 'bold',
            borderColor: 'secondary.light',
            '&:hover': {color: 'white', backgroundColor: 'secondary.light', borderColor: 'secondary.light'},
        },
        sx: {bgcolor: 'info.lighter', fontWeight: 'bold'}
    },
    // -----------------------------------------------------------------------------------------------------------------
    {
        id: 'divider',
        label: '',
        headSX: {minWidth: 150, height: 15, bgcolor: 'common.white', borderColor: 'primary.main'},
        sx: {bgcolor: 'common.white'}
    },
    {
        id: 'surplusDeficit',
        label: 'Contracted Surplus/(Deficit) (C=A-B)',
        headSX: {minWidth: 275, bgcolor: 'primary.light', fontWeight: 'bold', borderColor: 'primary.main'},
        sx: {bgcolor: 'primary.light', fontWeight: 'bold', color: 'white'},
        formatter: fCurrency, type: CellType.CURRENCY
    },
    {
        id: 'divider',
        label: '',
        headSX: {minWidth: 150, height: 15, bgcolor: 'common.white', borderColor: 'primary.main'},
        sx: {bgcolor: 'common.white'}
    },
    {
        id: 'undrawnCRECTC',
        label: 'Undrawn CRE CTC (D)',
        formatter: fCurrency,
        headSX: {minWidth: 220},
        type: CellType.CURRENCY
    },
    {
        id: 'fundFinance',
        label: 'Forecast Undrawn Fund Finance (F)',
        formatter: fCurrency,
        headSX: {minWidth: 220},
        type: CellType.CURRENCY
    },
    {
        id: 'sumUndrawn',
        label: 'Sum Forecasted Undrawn (\u03A3 of D)',
        formatter: fCurrency,
        headSX: {
            bgcolor: 'info.lighter',
            color: 'black',
            fontWeight: 'bold',
            borderColor: 'secondary.light',
            '&:hover': {color: 'white', backgroundColor: 'secondary.light', borderColor: 'secondary.light'},
        },
        sx: {bgcolor: 'info.lighter', fontWeight: 'bold'},
        type: CellType.CURRENCY,
    },
    {
        id: 'divider',
        label: '',
        headSX: {minWidth: 150, height: 15, bgcolor: 'common.white', borderColor: 'primary.main'},
        sx: {bgcolor: 'common.white'}
    },
    {
        id: 'adjContSurplusDeficit',
        label: 'Adj. Contr. Surplus/(Deficit) (E=C+D)',
        headSX: {minWidth: 250, bgcolor: 'primary.light', fontWeight: 'bold', borderColor: 'primary.main'},
        sx: {bgcolor: 'primary.light', fontWeight: 'bold', color: 'white'},
        formatter: fCurrency, type: CellType.CURRENCY
    },
    {
        id: 'divider',
        label: '',
        headSX: {minWidth: 150, height: 15, bgcolor: 'common.white', borderColor: 'primary.main'},
        sx: {bgcolor: 'common.white'}
    },
    {
        id: 'newCommitments',
        label: 'New Loan Commitments (F)',
        formatter: fCurrency,
        headSX: {minWidth: 220},
        type: CellType.CURRENCY
    },
    {
        id: 'subscriptions',
        label: 'Investor Subscriptions (F)',
        formatter: fCurrency,
        headSX: {minWidth: 220},
        type: CellType.CURRENCY
    },
    {
        id: 'redemptions',
        label: 'Investor Redemptions (F)',
        formatter: fCurrency,
        headSX: {minWidth: 220},
        type: CellType.CURRENCY
    },
    {
        id: 'repayments',
        label: 'Net Repayments (F)',
        formatter: fCurrency,
        headSX: {minWidth: 220},
        type: CellType.CURRENCY
    },
    {
        id: 'increaseBkFacilities',
        label: 'Increased Bank Facilities (F)',
        formatter: fCurrency,
        headSX: {minWidth: 220},
        type: CellType.CURRENCY
    },
    {
        id: 'adjustments',
        label: 'Sum of Forecasted Adj. (\u03A3 of F)',
        formatter: fCurrency,
        headSX: {
            bgcolor: 'info.lighter',
            color: 'black',
            fontWeight: 'bold',
            borderColor: 'secondary.light',
            '&:hover': {color: 'white', backgroundColor: 'secondary.light', borderColor: 'secondary.light'},
        },
        sx: {bgcolor: 'info.lighter', fontWeight: 'bold'},
        type: CellType.CURRENCY,
    },
    {
        id: 'divider',
        label: '',
        headSX: {minWidth: 150, height: 15, bgcolor: 'common.white', borderColor: 'primary.main'},
        sx: {bgcolor: 'common.white'}
    },
    {
        id: 'runningAdjustments',
        label: 'Total Previous Adj. (\u03A3 of prev. F)',
        formatter: fCurrency,
        headSX: {
            bgcolor: 'info.lighter',
            color: 'black',
            fontWeight: 'bold',
            borderColor: 'secondary.light',
            '&:hover': {color: 'white', backgroundColor: 'secondary.light', borderColor: 'secondary.light'},
        },
        sx: {bgcolor: 'info.lighter', fontWeight: 'bold'},
        type: CellType.CURRENCY,
    },
    {
        id: 'divider',
        label: '',
        headSX: {minWidth: 150, height: 15, bgcolor: 'common.white', borderColor: 'primary.main'},
        sx: {bgcolor: 'common.white'}
    },
    {
        id: 'adjClosingSurplusDeficit',
        label: 'Adj. Closing Surplus/(Deficit) (G=E+F)',
        headSX: {minWidth: 250, bgcolor: 'primary.light', fontWeight: 'bold', borderColor: 'primary.main'},
        sx: {bgcolor: 'primary.light', fontWeight: 'bold', color: 'white'},
        formatter: fCurrency, type: CellType.CURRENCY
    },
    {
        id: 'divider',
        label: '',
        headSX: {minWidth: 150, height: 30, bgcolor: 'common.white', borderColor: 'primary.main'},
        sx: {bgcolor: 'common.white'}
    },
    {
        id: 'commitmentsHeader',
        label: 'Closing Loan Commitments:',
        headSX: {minWidth: 250, bgcolor: 'primary.main', borderColor: 'primary.main'},
        sx: {bgcolor: 'primary.main', minWidth: 150}
    },
    {
        id: 'drawn',
        label: 'Drawn Commitments',
        formatter: fCurrency,
        headSX: {minWidth: 220},
        type: CellType.CURRENCY
    },
    {
        id: 'undrawn',
        label: 'Undrawn Commitments',
        formatter: fCurrency,
        headSX: {minWidth: 220},
        type: CellType.CURRENCY
    },
    {
        id: 'totalCommitment',
        label: 'Total Commitments',
        formatter: fCurrency,
        type: CellType.CURRENCY,
        headSX: {
            bgcolor: 'info.lighter',
            color: 'black',
            fontWeight: 'bold',
            borderColor: 'secondary.light',
            '&:hover': {color: 'white', backgroundColor: 'secondary.light', borderColor: 'secondary.light'},
        },
        sx: {bgcolor: 'info.lighter', fontWeight: 'bold'}
    },
    {
        id: 'divider',
        label: '',
        headSX: {minWidth: 150, height: 15, bgcolor: 'common.white', borderColor: 'primary.main'},
        sx: {bgcolor: 'common.white'}
    },
    {
        id: 'availCap',
        label: 'Available Capital',
        formatter: fCurrency,
        headSX: {minWidth: 220},
        type: CellType.CURRENCY
    },
    {
        id: 'analyAUM',
        label: 'AUM (Loan commit. + Ava. Cap. ($)',
        formatter: fCurrency,
        headSX: {minWidth: 220},
        type: CellType.CURRENCY
    },
    {
        id: 'divider',
        label: '',
        headSX: {minWidth: 150, height: 15, bgcolor: 'common.white', borderColor: 'primary.main'},
        sx: {bgcolor: 'common.white'}
    },
    {
        id: 'wavMargin',
        label: 'Weighted Average Margin (%)',
        formatter: fPercent,
        headSX: {minWidth: 220},
        type: CellType.CURRENCY
    },
    {
        id: 'baseRate',
        label: 'Base Rate (%)',
        formatter: fPercent,
        headSX: {minWidth: 220},
        type: CellType.CURRENCY
    },
    {
        id: 'wavYield',
        label: 'Drawn Rate (%)',
        formatter: fPercent,
        headSX: {minWidth: 220},
        type: CellType.CURRENCY
    },
    {
        id: 'divider',
        label: '',
        headSX: {minWidth: 150, height: 15, bgcolor: 'common.white', borderColor: 'primary.main'},
        sx: {bgcolor: 'common.white'}
    },
    {
        id: 'wavMaturity',
        label: 'Weighted Average Maturity (yrs)',
        headSX: {minWidth: 220},
        type: CellType.CURRENCY
    },
    {
        id: 'wavInterestRate',
        label: 'Weighted Average Interest (yrs)',
        headSX: {minWidth: 220},
        type: CellType.CURRENCY
    },
];

const investmentReportAnalyticsRows: Array<HorizontalTableRow> = [
    {
        id: 'wavRating',
        label: 'Weighted Average Rating',
        headSX: {minWidth: 220},
        type: CellType.CURRENCY
    },
    {
        id: 'wavRatingValue',
        label: 'Weighted Average Rating',
        headSX: {minWidth: 220},
        type: CellType.CURRENCY
    },
    {
        id: 'divider',
        label: '',
        headSX: {minWidth: 150, height: 15, bgcolor: 'common.white', borderColor: 'primary.main'},
        sx: {bgcolor: 'common.white'}
    },
    {
        id: 'senior',
        label: 'Senior (%)',
        headSX: {minWidth: 220},
        formatter: fPercent,
        type: CellType.CURRENCY
    },
    {
        id: 'subordinated',
        label: 'Subordinated (%)',
        headSX: {minWidth: 220},
        formatter: fPercent,
        type: CellType.CURRENCY
    },
    {
        id: 'equity',
        label: 'Equity (%)',
        headSX: {minWidth: 220},
        formatter: fPercent,
        type: CellType.CURRENCY
    },
    {
        id: 'other',
        label: 'Other (%)',
        headSX: {minWidth: 220},
        formatter: fPercent,
        type: CellType.CURRENCY
    },
    {
        id: 'divider',
        label: '',
        headSX: {minWidth: 150, height: 15, bgcolor: 'common.white', borderColor: 'primary.main'},
        sx: {bgcolor: 'common.white'}
    },
    {
        id: 'investmentGrade',
        label: 'Investment Grade (%)',
        headSX: {minWidth: 220},
        formatter: fPercent,
        type: CellType.CURRENCY
    },
    {
        id: 'subInvestmentGrade',
        label: 'Sub-Investment Grade (%)',
        headSX: {minWidth: 220},
        formatter: fPercent,
        type: CellType.CURRENCY
    },
    {
        id: 'divider',
        label: '',
        headSX: {minWidth: 150, height: 15, bgcolor: 'common.white', borderColor: 'primary.main'},
        sx: {bgcolor: 'common.white'}
    },
    {
        id: 'assets',
        label: '# Assets',
        subRows: [
            {id: 'avgSize', label: 'Average Asset Size ($)', formatter: fCurrency, type: CellType.CURRENCY},
            {id: 'largestExposure', label: 'Largest Exposure ($)', formatter: fCurrency, type: CellType.CURRENCY},
            {id: 'largestExposurePer', label: 'Largest Exposure (%)', formatter: fPercent},
            {id: 'topTenExposure', label: 'Top 10 Exposure ($)', formatter: fCurrency, type: CellType.CURRENCY},
            {id: 'topTenExposurePer', label: 'Top 10 Exposure (%)', formatter: fPercent},
        ]
    },
    {
        id: 'exposure',
        label: 'Industry Exposure %',
        subRows: []
    },
    {
        id: 'divider',
        label: '',
        headSX: {minWidth: 150, height: 15, bgcolor: 'common.white', borderColor: 'primary.main'},
        sx: {bgcolor: 'common.white'}
    },
    {
        id: 'domicile',
        label: 'Domicile (%)',
        headSX: {minWidth: 220},
        formatter: fPercent,
        type: CellType.CURRENCY
    },
    {
        id: 'aud',
        label: 'AUD (%)',
        headSX: {minWidth: 220},
        formatter: fPercent,
        type: CellType.CURRENCY
    },
]

export interface contractualOpening {
    contractualUndrawn: number,
    contractualDrawn: number,
    contractualTotalCommitment: number,

    contractualFum: number,
    contractualFacilities: number,
    contractualExternalFacilities: number,
    contractualInternalFacilities: number,
    contractualCapitalAUM: number,

    surplusDeficit: number
}

export interface investmentReportColumn extends HorizontalTableColumn {
    contractualDrawn: number,
    contractualUndrawn: number,
    contractualTotalCommitment: number,

    contractualFum: number,
    contractualFacilities: number,
    contractualCapitalAUM: number,

    surplusDeficit: number,

    undrawnCRECTC: number,
    fundFinance: number,

    sumUndrawn: number,
    adjContSurplusDeficit: number,

    //------------------------------------
    newCommitments: number,
    subscriptions: number,
    redemptions: number,
    repayments: number,
    increaseBkFacilities: number,

    adjustments: number,
    runningAdjustments: number

    adjClosingSurplusDeficit: number

    undrawn: number,
    drawn: number,
    totalCommitment: number,

    availCap: number,
    analyAUM: number,

    wavMargin: number,
    baseRate: number,
    wavYield: number,
    wavMaturity: number,
    wavInterestRate: number,

    wavCreditRating: number,


    senior: number,
    subordinated: number,
    equity: number,
    other: number,
    // seniorPer: number,
    // subordinatedPer: number,
    // equityPer: number,
    // otherPer: number,
    capPer: number,

    investmentGrade: number,
    subInvestmentGrade: number,

    assets: number,
    avgSize: number,
    largestExposure: number,
    largestExposurePer: number,

    industry: Array<{ industry: string, percentage: number }>

    domicile: number,
    aud: number
}

export const investmentReportSelector = createSelector(
    (state: RootState) => state.capitalBudget.capitalBudget.budget,
    forecastCapitalDataSelector,
    fundForecastDataSelector,
    fundHolderSelector,
    retrieveBaseRate,
    (_state: RootState, avaCapToggle: boolean) => avaCapToggle,
    (budget, forecastReport, fundForecast, fund, baseRate, avaCapToggle): {
        data: Array<investmentReportColumn>,
        capitalRows: Array<HorizontalTableRow>,
        analyticsRows: Array<HorizontalTableRow>
    } => {
        // Return blank if no data
        if (!budget || !forecastReport || !fundForecast) {
            return {
                data: [],
                capitalRows: investmentReportCapitalRows,
                analyticsRows: investmentReportAnalyticsRows
            }
        }

        // Copy Rows
        const capitalRows = _.cloneDeep(investmentReportCapitalRows);
        const analyticsRows = _.cloneDeep(investmentReportAnalyticsRows);

        // SENIORITY ADDITIONAL ROWS
        const otherRankings: Array<string> = [];

        // Handle GICS Mapping
        const gics = generateGICSrows();
        let gicsMap = gics.gicsMap;

        // DECONSTRUCT FORECASTED PORTFOLIO
        const {
            base,
            weeks,
            months
        } = budget;

        let contractualOpening: contractualOpening = {
            contractualUndrawn: 0,
            contractualDrawn: 0,
            contractualTotalCommitment: 0,

            contractualFum: forecastReport.base.openingFum,
            contractualFacilities: forecastReport.base.totalBankFacilities,
            contractualExternalFacilities: forecastReport.base.externalFacilities,
            contractualInternalFacilities: forecastReport.base.internalFacilities,
            contractualCapitalAUM: addValues(forecastReport.base.openingFum, forecastReport.base.totalBankFacilities),

            surplusDeficit: 0
        }

        // HANDLE BASE COLUMN
        let baseData = summariseInvestmentRepPeriod(base, forecastReport.base, fundForecast.base, contractualOpening, gics, gicsMap, fund, baseRate, avaCapToggle, true);

        contractualOpening = {
            ...contractualOpening,
            contractualUndrawn: baseData.contractualUndrawn,
            contractualDrawn: baseData.contractualDrawn,
            contractualTotalCommitment: baseData.contractualTotalCommitment,

            surplusDeficit: addValues(baseData.contractualCapitalAUM, -baseData.contractualTotalCommitment)
        }

        baseData = {
            ...baseData,

            label: 'Contractual Base',

            ...contractualOpening
        }

        baseData.openingUndrawn = baseData.contractualUndrawn;
        baseData.openingDrawn = baseData.contractualDrawn;
        baseData.openingTotalCommitment = baseData.contractualTotalCommitment;

        // OTHER SENIORITY RANKINGS
        // FOR BASE - Handle Other Seniority Rankings
        for (const [key, value] of baseData.otherRanking.entries()) {
            otherRankings.push(key);
            baseData[key] = value;
        }

        let weekRunningAdjustments = 0;

        const weeksData = weeks.map((week, w) => {
            const weekData = summariseInvestmentRepPeriod(week, forecastReport.weeks[w], fundForecast.weeks[w], contractualOpening, gics, gicsMap, fund, baseRate, avaCapToggle, false);

            weekData.adjContSurplusDeficit = addValues(weekData.surplusDeficit, weekData.sumUndrawn);
            // Manual FF defines the manually adjusted portion of the FF Undrawn
            weekData.manualFF = forecastReport.weeks[w].manualFF;

            weekData.runningAdjustments = weekRunningAdjustments;
            weekRunningAdjustments = addValues(weekRunningAdjustments, weekData.adjustments);

            weekData.adjClosingSurplusDeficit = addArrayValues([weekData.adjustments, weekData.runningAdjustments, weekData.adjContSurplusDeficit]);

            return weekData;
        });

        let monthRunningAdjustments = 0;

        const monthsData = months.map((month, m) => {
            const monthData = summariseInvestmentRepPeriod(month, forecastReport.months[m], fundForecast.months[m], contractualOpening, gics, gicsMap, fund, baseRate, avaCapToggle, false)

            monthData.adjContSurplusDeficit = addValues(monthData.surplusDeficit, monthData.sumUndrawn);
            // Manual FF defines the manually adjusted portion of the FF Undrawn
            monthData.manualFF = forecastReport.months[m].manualFF;

            monthData.runningAdjustments = monthRunningAdjustments;
            monthRunningAdjustments = addValues(monthRunningAdjustments, monthData.adjustments);

            monthData.adjClosingSurplusDeficit = addArrayValues([monthData.adjustments, monthData.runningAdjustments, monthData.adjContSurplusDeficit]);

            return monthData;
        });

        weeksData.forEach(w => {
            w.headSX = {minWidth: '140px'}
            for (const [key, value] of w.otherRanking.entries()) {
                if (!otherRankings.includes(key)) otherRankings.push(key);
                baseData[key] = value;
            }
        })

        monthsData.forEach(m => {
            m.headSX = {minWidth: '140px'}
            for (const [key, value] of m.otherRanking.entries()) {
                if (!otherRankings.includes(key)) otherRankings.push(key);
                baseData[key] = value;
            }
        })

        // INSERT INDUSTRY ROWS
        analyticsRows[12].subRows = Array.from(gicsMap.values()).reduce((rows: Array<HorizontalTableRow>, sector) => {
            if (sector.show) {
                const sectorRow: IndustryRow = {
                    id: sector.id,
                    label: sector.label,
                    formatter: fPercent,
                    type: CellType.CURRENCY,
                    subRows: [],
                    headSX: sector.headSX,
                    sx: sector.sx
                }

                sector.industryGroup.forEach((group) => {
                    if (group.show) {
                        const groupRow: IndustryRow = {
                            id: group.id,
                            label: group.label,
                            formatter: fPercent,
                            type: CellType.CURRENCY,
                            subRows: [],
                            headSX: group.headSX,
                            sx: group.sx
                        }

                        group.industries.forEach((industry) => {
                            if (industry.show) {
                                groupRow.subRows.push(industry);
                            }
                        })

                        sectorRow.subRows.push(groupRow);
                    }
                })

                rows.push(sectorRow);
            }
            return rows;
        }, []);

        // ADD ADDITIONAL SENIORITY RANKINGS AS ROWS;
        analyticsRows.splice(8, 0, ...otherRankings.map(r => ({
            id: r,
            label: `${r} (%)`,
            formatter: fPercent,
            type: CellType.CURRENCY
        })))

        weeksData[0].sx = {borderLeft: 10, borderLeftColor: 'primary.main'};
        monthsData[0].sx = {borderLeft: 10, borderLeftColor: 'primary.main'};

        const data = [
            baseData,
            ...weeksData,
            ...monthsData
        ]

        for (let i = 1; i < data.length; i += 2) {
            data[i].sx = {...data[i].sx}
        }

        if (avaCapToggle) {
            analyticsRows[12].subRows.push({
                id: 'capPer',
                label: 'Available Capital',
                formatter: fPercent,
                type: CellType.CURRENCY,
                headSX: {bgcolor: 'primary.dark'},
                sx: {bgcolor: 'info.lighter'},
            })

            analyticsRows.splice(7, 0, {
                id: 'capPer',
                label: 'Available Capital (%)',
                headSX: {minWidth: 220},
                formatter: fPercent,
                type: CellType.CURRENCY
            },)
        }

        return {
            data,
            capitalRows,
            analyticsRows
        }
    }
)

function summariseInvestmentRepPeriod(period: ForecastPeriod, forecastReportPeriod: forecastCapitalColumn, fundForcastPeriod: fundForecastColumn, contractualOpening: contractualOpening, gics: GICSRowData, gicsMap: GICSRowMap, fund: FundDetails | null, baseRate: number | null, includeCap: boolean, base: boolean): investmentReportColumn {

    let results: investmentReportColumn = {
        ...contractualOpening,

        undrawnCRECTC: forecastReportPeriod.creCTCCommitments,
        fundFinance: forecastReportPeriod.unusedFundFinance,

        sumUndrawn: addValues(forecastReportPeriod.creCTCCommitments, forecastReportPeriod.unusedFundFinance),

        adjContSurplusDeficit: 0,

        newCommitments: forecastReportPeriod.newCommitments,
        subscriptions: forecastReportPeriod.subscriptions,
        redemptions: forecastReportPeriod.redemptions,
        repayments: forecastReportPeriod.repayments,
        increaseBkFacilities: forecastReportPeriod.bankFacilities,

        adjustments: addArrayValues([
            forecastReportPeriod.newCommitments,
            forecastReportPeriod.subscriptions,
            forecastReportPeriod.redemptions,
            forecastReportPeriod.repayments,
            forecastReportPeriod.bankFacilities
        ]),
        runningAdjustments: 0,
        adjustedSurplusDeficit: 0,

        adjClosingSurplusDeficit: 0,

        drawn: 0,
        undrawn: 0,
        totalCommitment: 0,

        availCap: (base) ? fundForcastPeriod.startingAvailableCap : (fundForcastPeriod.closingAvailableCap > 0 ? fundForcastPeriod.closingAvailableCap : 0),
        analyAUM: 0,

        wavMargin: 0,
        baseRate: baseRate || 0,
        wavYield: 0,
        wavMaturity: 0,
        wavInterestRate: 0,

        wavCreditRating: 0,

        senior: 0,
        subordinated: 0,
        equity: 0,
        other: 0,
        capPer: 0,

        investmentGrade: 0,
        subInvestmentGrade: 0,

        assets: 0,
        avgSize: 0,
        label: "",
        largestExposure: 0,
        largestExposurePer: 0,

        industry: [],

        domicile: 0,
        aud: 0,
    };

    // RUNNING TOTALS FOR WAV CALCS
    let wavYieldTotal = 0;
    let wavMarginCalc = 0;
    let wavYieldCalc = 0;
    let wavMaturityTotal = 0;
    let wavMaturityCalc = 0;
    let wavInterestTotal = 0;
    let wavInterestCalc = 0;

    // Available Capital Inclusion
    let wavRatingTotal = 0;
    let wavRatingCalc = 0;
    // DEBT RANKING
    let otherRanking = new Map<string, number>();
    let senior = 0;
    let subordinated = 0;
    let equity = 0;
    let other = 0;
    // Investment Grade
    let investmentGrade = 0;
    let subInvestmentGrade = 0;
    // AUD Domicile
    let domicile = 0;
    let aud = 0;

    // ASSSETS
    let assetsMap = new Map<string | number, number>();

    // FILTER BOOK FOR THOSE ACTIVE OR IF SELECTED FUND
    const filteredBook = createFilteredPortfolioBook(period.book, fund);

    filteredBook.forEach(loan => {
        // WHEN BASE PORTFOLIO EXCLUDE INHERITED LOANS
        if ((base && (!fund || (fund && loan.fund === fund.label)))) {
            results.contractualUndrawn = addValues(results.contractualUndrawn, loan.updatedUndrawn);
            results.contractualDrawn = addValues(results.contractualDrawn, loan.updatedDrawn);
            results.contractualTotalCommitment = addValues(results.contractualTotalCommitment, loan.updatedValue);
        }

        results.undrawn = addValues(results.undrawn, loan.updatedUndrawn);
        results.drawn = addValues(results.drawn, loan.updatedDrawn);
        results.totalCommitment = addValues(results.totalCommitment, loan.updatedValue);

        // WAV YIELD AND WAV MARGIN
        if (!['Equity', 'Other'].includes(loan.ranking) || (['Equity', 'Other'].includes(loan.ranking) && loan.margin > 0)) {
            wavYieldTotal = addValues(wavYieldTotal, loan.updatedValue);

            // DETERMINE MARGIN
            const facilityFee = ((loan.facilityFee === 'Facility Limit') ? loan.facilityFeeRate || 0 : 0);
            const margin = addArrayValues([loan.margin, facilityFee]);

            wavMarginCalc = addValues(wavMarginCalc, margin * loan.updatedValue);
            if (baseRate) {
                wavYieldCalc = addValues(wavYieldCalc, loan.updatedValue * ((loan.interestType === 'Fixed Rate') ? margin : margin + baseRate));
            }
        }

        const tenor = loan.updatedTenor;

        // WAV TENOR/MATURITY
        if (!['Equity', 'Other'].includes(loan.ranking)) {
            wavMaturityTotal = addValues(wavMaturityTotal, loan.updatedValue);
            wavMaturityCalc = addValues(wavMaturityCalc, (tenor * loan.updatedValue));
        }

        // WAV INTEREST RATE DURATION
        if (loan.interestType !== 'Other') {
            wavInterestTotal = addValues(wavInterestTotal, loan.updatedValue);
            const weightedDuration = (loan.interestType === 'Fixed Rate') ? (tenor * loan.updatedValue) : (0.1 * loan.updatedValue);
            wavInterestCalc = addValues(wavInterestCalc, weightedDuration);
        }

        // WAV RATING AND INVESTMENT GRADE
        const ratingValue = loan.rating ? convertRatingToValue((loan.rating)) : null;
        if (ratingValue) {
            wavRatingTotal = addValues(wavRatingTotal, loan.updatedValue);
            wavRatingCalc = addValues(wavRatingCalc, ratingValue * loan.updatedValue);
            if (ratingValue <= 12) {
                investmentGrade = addValues(investmentGrade, loan.updatedValue);
            } else {
                subInvestmentGrade = addValues(subInvestmentGrade, loan.updatedValue);
            }
        } else {
            subInvestmentGrade = addValues(subInvestmentGrade, loan.updatedValue);
        }

        // CALCULATE SENIORITY RANK BREAKDOWN
        switch (loan.ranking) {
            case 'Senior':
                senior = addValues(senior, loan.updatedValue);
                break;
            case 'Subordinated':
                subordinated = addValues(subordinated, loan.updatedValue);
                break;
            case 'Equity':
                equity = addValues(equity, loan.updatedValue);
                break;
            case 'Other':
                other = addValues(other, loan.updatedValue);
                break;
            default:
                if (!!loan.ranking) {
                    if (otherRanking.get(loan.ranking)) {
                        otherRanking.set(loan.ranking, addValues(otherRanking.get(loan.ranking) || 0, loan.updatedValue));
                    } else {
                        otherRanking.set(loan.ranking, loan.updatedValue);
                    }
                } else {
                    if (otherRanking.get('')) {
                        otherRanking.set('', addValues(otherRanking.get('') || 0, loan.updatedValue));
                    } else {
                        otherRanking.set('', loan.updatedValue);
                    }
                }
        }

        // CALCULATE INDUSTRY BREAKDOWN
        if (loan.industry && `industry${loan.industry}` in gics.industries) {
            results[`industry${loan.industry}`] = addValues(results[`industry${loan.industry}`], loan.updatedValue);
        } else {
            results['industryother'] = addValues(results['industryother'], loan.updatedValue);
        }
        // Industry Group
        if (loan.industryGroup && `group${loan.industryGroup}` in gics.industryGroups) {
            results[`group${loan.industryGroup}`] = addValues(results[`group${loan.industryGroup}`], loan.updatedValue);
        } else {
            results.groupother = addValues(results.groupother, loan.updatedValue);
        }
        // Sector
        if (loan.sector && `sector${loan.sector}` in gics.sectors) {
            results[`sector${loan.sector}`] = addValues(results[`sector${loan.sector}`], loan.updatedValue);
            // MARK GICS MAP SHOW TRUE
            let sector = gicsMap.get(loan.sector);
            if (sector) {
                sector.show = true;
                let industryGroup = sector.industryGroup.get(`${loan.industryGroup}`);
                if (industryGroup) {
                    industryGroup.show = true;
                    let industry = industryGroup.industries.get(`${loan.industry}`);
                    if (industry) {
                        industry.show = true;
                        industryGroup.industries.set(`${loan.industry}`, industry);
                    }
                }
                gicsMap.set(`${loan.sector}`, sector);
            }
        } else {
            results.sectorother = addValues(results.sectorother, loan.updatedValue);
            let sector = gicsMap.get('other');
            if (sector) {
                sector.show = true;
                let industryGroup = sector.industryGroup.get('other');
                if (industryGroup) {
                    industryGroup.show = true;
                    let industry = industryGroup.industries.get('other');
                    if (industry) {
                        industry.show = true;
                        industryGroup.industries.set('other', industry);
                    }
                    sector.industryGroup.set('other', industryGroup);
                }
                gicsMap.set('other', sector);
            }
        }

        // ASSETS
        if (assetsMap.get(loan.asset)) {
            assetsMap.set(loan.asset, addValues(assetsMap.get(loan.asset) || 0, loan.updatedValue));
        } else {
            assetsMap.set(loan.asset, loan.updatedValue)
        }

        // Portfolio Domicile and Currency
        if (loan.domicile === 'Australia') {
            domicile = addValues(domicile, loan.value);
        }
        if (loan.baseCurrency === 'AUD') {
            aud = addValues(aud, loan.value);
        }
    })

    results.analyAUM = addValues(results.totalCommitment, results.availCap);

    // FINALISE WAV MARGIN, YIELD, TENOR
    results.wavMargin = Math.round((wavMarginCalc / wavYieldTotal) * 10000) / 10000;
    results.wavYield = Math.round((wavYieldCalc / wavYieldTotal) * 10000) / 10000;

    results.wavMaturity = Math.round((wavMaturityCalc / wavMaturityTotal) * 100) / 100;
    results.wavInterestRate = Math.round((wavInterestCalc / wavInterestTotal) * 100) / 100;

    // ASSETS ORDERING
    // Portfolio Asset Calcs
    let assets: Array<{ asset: string | number, value: number }> = [];

    assetsMap.forEach((value, key) => assets.push({asset: key, value: value}));

    assets.sort((a, b) => a.value > b.value ? -1 : 1);


    for (let a = 0; a < Math.min(assets.length, 10); a++) {
        results.topTenExposure = addValues(results.topTenExposure, assets[a].value);
    }

    // IF AVAILABLE CAPITAL INCLUDED AND AVAILABLE CAPITAL > 0;
    if (includeCap && results.availCap > 0) {
        // TREAT AVAILABLE CAPITAL AS AA-
        wavRatingTotal = addValues(wavRatingTotal, results.availCap);
        wavRatingCalc = addValues(wavRatingCalc, results.availCap * 6);

        results.senior = senior / results.analyAUM;
        results.subordinated = subordinated / results.analyAUM;
        results.equity = equity / results.analyAUM;
        results.other = other / results.analyAUM;
        results.capPer = results.availCap / results.analyAUM;
        for (const [key, value] of otherRanking.entries()) {
            otherRanking.set(key, (value / results.analyAUM));
        }

        investmentGrade = addValues(investmentGrade, results.availCap);
        results.investmentGrade = investmentGrade / results.analyAUM;
        results.subInvestmentGrade = subInvestmentGrade / results.analyAUM;

        // Portfolio Industry Percentages
        // sectors
        for (const sector in gics.sectors) {
            results[sector] = results[sector] / results.analyAUM;
        }
        // industry groups
        for (const group in gics.industryGroups) {
            results[group] = results[group] / results.analyAUM;
        }
        // industries
        for (const industry in gics.industries) {
            results[industry] = results[industry] / results.analyAUM;
        }

        domicile = addValues(domicile, results.availCap);
        results.domicile = domicile / results.analyAUM;

        aud = addValues(aud, results.availCap);
        results.aud = aud / results.analyAUM;
    } else {
        results.senior = senior / results.totalCommitment;
        results.subordinated = subordinated / results.totalCommitment;
        results.equity = equity / results.totalCommitment;
        results.other = other / results.totalCommitment;
        for (const [key, value] of otherRanking.entries()) {
            otherRanking.set(key, (value / results.totalCommitment));
        }

        results.investmentGrade = investmentGrade / results.totalCommitment;
        results.subInvestmentGrade = subInvestmentGrade / results.totalCommitment;

        // Portfolio Industry Percentages
        // sectors
        for (const sector in gics.sectors) {
            results[sector] = results[sector] / results.totalCommitment;
        }
        // industry groups
        for (const group in gics.industryGroups) {
            results[group] = results[group] / results.totalCommitment;
        }
        // industries
        for (const industry in gics.industries) {
            results[industry] = results[industry] / results.totalCommitment;
        }

        results.domicile = domicile / results.totalCommitment;
        results.aud = aud / results.totalCommitment;
    }

    // WAV Rating
    results.wavRatingValue = Math.round((wavRatingCalc / wavRatingTotal) * 100) / 100;
    results.wavRating = convertValueToRating(results.wavRatingValue);

    // Asset Size
    results.assets = assetsMap.size;
    results.avgSize = results.totalCommitment / (assetsMap.size);
    results.largestExposure = assets[0]?.value || 0;
    results.largestExposurePer = (assets[0]?.value || 0) / results.totalCommitment;
    results.topTenExposurePer = results.topTenExposure / results.totalCommitment;


    return {
        ...results,

        otherRanking,

        label: period.label,
        labelTwo: period.labelTwo,
        tooltip: period.tooltip,
        type: period.type
    }
}

type IndustryRow = HorizontalTableRow & {
    subRows: Array<HorizontalTableRow>
}

type GICSRowMap = Map<string, {
    id: string,
    label: string,
    formatter: (value: number) => string,
    type: CellType,
    headSX: any,
    sx: any,
    show: boolean,
    industryGroup: Map<string, {
        id: string,
        label: string,
        formatter: (value: number) => string,
        type: CellType,
        headSX: any,
        sx: any,
        show: boolean,
        industries: Map<string, {
            id: string,
            label: string,
            formatter: (value: number) => string,
            type: CellType,
            headSX: any,
            sx: any,
            show: boolean
        }>,
    }>;
}>;

type GICSRowData = {
    gicsMap: GICSRowMap,
    sectors: {
        [x: string]: number
    },
    industryGroups: {
        [x: string]: number
    },
    industries: {
        [x: string]: number
    }
}

function generateGICSrows() {
    let gicsMap: GICSRowMap = new Map();
    let sectors: { [x: string]: number } = {};
    let industryGroups: { [x: string]: number } = {};
    let industries: { [x: string]: number } = {};

    for (const [sectorName, sector] of Object.entries(spIndustries)) {
        let sectorRow = {
            id: `sector${sectorName}`,
            label: sectorName,
            formatter: fPercent,
            type: CellType.CURRENCY,
            industryGroup: new Map(),
            headSX: {bgcolor: 'primary.dark'},
            sx: {bgcolor: 'info.lighter'},
            show: false
        }

        for (const [industryGroupName, industryGroup] of Object.entries(sector)) {
            let industryGroupRow = {
                id: `group${industryGroupName}`,
                label: industryGroupName,
                formatter: fPercent,
                type: CellType.CURRENCY,
                industries: new Map(),
                headSX: {bgcolor: 'primary.light'},
                sx: {bgcolor: 'info.dark'},
                show: false,
            }
            industryGroups[`group${industryGroupName}`] = 0;

            for (const [industryName] of Object.entries(industryGroup)) {
                industryGroupRow.industries.set(industryName, {
                    id: `industry${industryName}`,
                    label: industryName,
                    formatter: fPercent,
                    type: CellType.CURRENCY,
                    headSX: {bgcolor: 'secondary.light'},
                    sx: {bgcolor: 'info.lighter'},
                    show: false
                });
                industries[`industry${industryName}`] = 0;
            }
            sectorRow.industryGroup.set(industryGroupName, industryGroupRow);
            sectors[`sector${sectorName}`] = 0;
        }

        gicsMap.set(sectorName, sectorRow);

    }

    let otherSectorRow = {
        id: 'sectorother',
        label: 'Other Sector',
        formatter: fPercent,
        type: CellType.CURRENCY,
        industryGroup: new Map([
            ['other', {
                id: 'groupother',
                label: 'Other Industry Group',
                formatter: fPercent,
                type: CellType.CURRENCY,
                industries: new Map([
                    ['other', {
                        id: 'industryother',
                        label: 'Other Industry',
                        formatter: fPercent,
                        type: CellType.CURRENCY,
                        headSX: {bgcolor: 'secondary.light'},
                        sx: {bgcolor: 'info.lighter'},
                        show: false
                    }]
                ]),
                headSX: {bgcolor: 'primary.light'},
                sx: {bgcolor: 'info.dark'},
                show: false
            }]
        ]),
        headSX: {bgcolor: 'primary.dark'},
        sx: {bgcolor: 'info.lighter'},
        show: false
    };

    gicsMap.set('other', otherSectorRow);

    sectors.sectorother = 0;
    industryGroups.groupother = 0;
    industries.industryother = 0;

    return {
        gicsMap,
        sectors,
        industryGroups,
        industries
    };
}