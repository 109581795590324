import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "../../../../store/store";
import {
    budgetPeriodArraySelector,
    FundDetails,
    fundHolderSelector
} from "../../../../store/capitalBudget/selectors/generalSelectors";
import {createFilteredPortfolioBook} from "../../../../utils/CapitalBudgetUtils";
import {addValues} from "../../../../utils/mathUtil";
import {LoanTags} from "../../../../types/capitalBudgetEnums";
import {CalculationLoanType, ForecastPeriod} from "../../../../types/capitalBudgetTypes";

interface DrillDownSelectorArgs {
    column: string,
    periodType: 'base' | 'week' | 'month',
    filterFunction?: (loan: CalculationLoanType) => boolean,
    base?: boolean
}

interface DrilldownLoanObject {
    id: number | string,
    trancheId: number | string,
    borrower: string,
    tranche: string,
    baseCurrency: string,
    domicile: string,
    tenor: number | null,
    interestType: string | null,
    margin: number | null,
    facilityFee: number | null,
    sector: string | null,
    industryGroup: string | null,
    industry: string | null,

    startDate: number | Date,
    maturity: number | Date,
    undrawn: number,
    drawn: number,
    value: number,
    fund: string | null
}

export const RetrieveDrillDownPeriod = createSelector(
    budgetPeriodArraySelector,
    (_state: RootState, args: DrillDownSelectorArgs) => args.column,
    (_state: RootState, args: DrillDownSelectorArgs) => args.periodType,
    (_state: RootState, args: DrillDownSelectorArgs) => args.base,
    (budget, column, periodType, base) => {
        if (!budget) {
            return undefined;
        }
        // FIND BOOK
        let period: ForecastPeriod | undefined;
        // IF PREVIOUS RETRIEVE PREVIOUS COLUMN BOOK
        if (base) {
            period = budget[0];
        } else {
            if (periodType === 'base') {
                period = budget[0];
            } else {
                period = budget.find(p => p.label === column && p.type === periodType);
            }
        }
        return period;
    }
)

export function LoanSummaryFilter(period: ForecastPeriod, fund: FundDetails | null, filter: (loan: CalculationLoanType) => boolean = () => true, showLookthrough: boolean = true) {
    let book = period.book;

    // FILTER BOOK IF FUND;
    // book = createFilteredPortfolioBook(book, fund, false);

    let lookthrough = false;

    const tranches = book.reduce((tranches, loan) => {
        if (!showLookthrough && loan.fund !== fund?.label) return tranches;

        if (!filter(loan)) return tranches;

        const key = loan.loanType === 'AXCESS' ? loan.trancheId : loan.id;

        if (key) {
            const tranche = tranches.get(key);

            if (!lookthrough && loan.tags.includes(LoanTags.LOOKTHROUGH)) lookthrough = true;

            if (!tranche || fund) {
                tranches.set(key, {
                    id: key,
                    trancheId: loan.loanType === 'AXCESS' ? (loan.trancheId || '') : 'NEW',
                    borrower: loan.name,
                    tranche: loan.tranche,
                    baseCurrency: loan.baseCurrency,
                    domicile: loan.domicile,
                    tenor: loan.updatedTenor,
                    interestType: loan.interestType,
                    margin: loan.margin,
                    facilityFee: loan.facilityFeeRate,
                    sector: loan.sector,
                    industryGroup: loan.industryGroup,
                    industry: loan.industry,

                    startDate: loan.startDate,
                    maturity: loan.amendedMaturity || loan.endDate,

                    undrawn: loan.updatedUndrawn,
                    drawn: loan.updatedDrawn,
                    value: loan.updatedValue,

                    fund: loan.tags.includes(LoanTags.LOOKTHROUGH) ? loan.fund : null,
                })
            } else {
                tranche.undrawn = addValues(tranche.undrawn, loan.updatedUndrawn);
                tranche.drawn = addValues(tranche.drawn, loan.updatedDrawn);
                tranche.value = addValues(tranche.value, loan.updatedValue);
            }
        }

        return tranches;
    }, new Map<number | string, DrilldownLoanObject>());

    return {loans: Array.from(tranches.values()), lookthrough}
}

// Retrieves list of loans from a periods loan book based on column and period type
export const LoanDrillDownSelector = createSelector(
    (state: RootState, args: DrillDownSelectorArgs) => RetrieveDrillDownPeriod(state, args),
    fundHolderSelector,
    (_state: RootState, args: DrillDownSelectorArgs) => args.filterFunction,
    (period, fund, filter = () => true) => {
        if (!period) {
            return {loans: [], lookthrough: false}
        }
        let book = period.book;

        // FILTER BOOK IF FUND;
        book = createFilteredPortfolioBook(book, fund, false);

        let lookthrough = false;

        const tranches = book.reduce((tranches, loan) => {
            if (!filter(loan)) return tranches;

            const key = loan.loanType === 'AXCESS' ? loan.trancheId : loan.id;

            if (key) {
                const tranche = tranches.get(key);

                if (!lookthrough && loan.tags.includes(LoanTags.LOOKTHROUGH)) lookthrough = true;

                if (!tranche || fund) {
                    tranches.set(key, {
                        id: key,
                        trancheId: loan.loanType === 'AXCESS' ? (loan.trancheId || '') : 'NEW',
                        borrower: loan.name,
                        tranche: loan.tranche,
                        baseCurrency: loan.baseCurrency,
                        domicile: loan.domicile,
                        tenor: loan.updatedTenor,
                        interestType: loan.interestType,
                        margin: loan.margin,
                        facilityFee: loan.facilityFeeRate,
                        sector: loan.sector,
                        industryGroup: loan.industryGroup,
                        industry: loan.industry,

                        startDate: loan.startDate,
                        maturity: loan.amendedMaturity || loan.endDate,

                        undrawn: loan.updatedUndrawn,
                        drawn: loan.updatedDrawn,
                        value: loan.updatedValue,

                        fund: loan.tags.includes(LoanTags.LOOKTHROUGH) ? loan.fund : null,
                    })
                } else {
                    tranche.undrawn = addValues(tranche.undrawn, loan.updatedUndrawn);
                    tranche.drawn = addValues(tranche.drawn, loan.updatedDrawn);
                    tranche.value = addValues(tranche.value, loan.updatedValue);
                }
            }

            return tranches;
        }, new Map<number | string, DrilldownLoanObject>());

        return {loans: Array.from(tranches.values()), lookthrough}
    }
)
