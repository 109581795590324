import {memo} from "react";
import {CalculationLoanType} from "../../../../types/capitalBudgetTypes";
import {CapitalAction, InvestmentType, LoanTags} from "../../../../types/capitalBudgetEnums";
import {Capital} from "../../../../types/forecastTypes";
// Components
import {DrillDownsSimple, HorizontalTableRow, valueCurrency} from "../../../../components";
import {investmentReportColumn} from "../../../../store/capitalBudget/selectors/Reports/investmentReport";
import LoansDrilldownTable from "./LoansDrilldownTable";
import RepaymentsDrilldown from "./RepaymentsDrilldown";
import {RetrieveDrillDownPeriod} from "./SummaryDrilldownsUtils";
// STORE
import {useAppSelector} from "../../../../store/store";
import {fundHolderSelector} from "../../../../store/capitalBudget/selectors/generalSelectors";
import FUMDrillDown from "./FUMDrillDown";
import FacilitiesDrillDown from "./FacilitiesDrillDown";
import {Box, Typography} from "@mui/material";
import CapitalDrillDownTable from "./CapitalDrillDownTable";
import AdjustmentsDrillDown from "./AdjustmentsDrillDown";
import PriorAdjustmentsDrilldown from "./PriorAdjustmentsDrilldown";
import DebtSeniorityDrillDown from "./DebtSeniorityDrillDown";
import CreditRatingsDrillDown from "./CreditRatingsDrillDown";
import AudDrillDown from "./AudDrillDown";
import DomicileDrillDown from "./DomicleDrillDown";
import {roundToDecimal} from "../../../../utils/mathUtil";
import RatesDrillDown from "./RatesDrillDown";
import IndustryDrilldown from "./IndustryDrilldown";
import ExposureDrilldown from "./ExposureDrilldown";
import AssetsDrillDown from "./AssetsDrillDown";
import FundFinanceDrilldown from "./FundFinanceDrillDown";


interface SummaryDrilldownProps {
    row: HorizontalTableRow,
    column: investmentReportColumn,
    data: Array<investmentReportColumn>,
    avaCapToggle: boolean
}

// Drilldowns for line items in Investment Summary Report
function SummaryDrilldown({row, column, data, avaCapToggle}: SummaryDrilldownProps) {

    const base = !!(row.id && ['contractualDrawn', 'contractualUndrawn', 'contractualTotalCommitment', 'contractualFum', 'contractualFacilities', 'contractualCapitalAUM', 'surplusDeficit'].includes(row.id))

    const period = useAppSelector(state => RetrieveDrillDownPeriod(state, {
        column: column.label,
        periodType: column.type,
        base
    }));

    const fund = useAppSelector(state => fundHolderSelector(state));

    if (!period) {
        return (
            <>
                No Data to Found
            </>
        )
    }

    switch (row.id) {
        // CONTRACTUAL DRAWN LOANS
        case 'contractualDrawn':
            return (
                <>
                    <LoansDrilldownTable
                        title={'Contractual Drawn Commitments'}
                        period={period}
                        fund={fund}
                        additionalColumns={[
                            {
                                field: 'drawn',
                                headerName: 'Drawn',
                                ...valueCurrency,
                                width: 120
                            }
                        ]}
                        filterFunction={(loan: CalculationLoanType) => loan.tags.includes(LoanTags.ACTIVE) && loan.updatedDrawn !== 0}
                        showLookThrough={false}
                    />
                </>
            );

        // CONTRACTUAL UNDRAWN LOANS
        case 'contractualUndrawn':
            return (
                <>
                    <LoansDrilldownTable
                        title={'Contractual Undrawn Commitments'}
                        period={period}
                        fund={fund}
                        additionalColumns={[
                            {
                                field: 'undrawn',
                                headerName: 'Undrawn',
                                ...valueCurrency,
                                width: 120
                            }
                        ]}
                        filterFunction={(loan: CalculationLoanType) => loan.tags.includes(LoanTags.ACTIVE) && loan.updatedUndrawn !== 0}
                        showLookThrough={false}
                    />
                </>
            );

        // CONTRACTUAL TOTAL COMMITMENT
        case 'contractualTotalCommitment':
            return (
                <>
                    <LoansDrilldownTable
                        title={'Contractual Commitments'}
                        period={period}
                        fund={fund}
                        additionalColumns={[
                            {
                                field: 'value',
                                headerName: 'Commitment',
                                ...valueCurrency,
                                width: 120
                            }
                        ]}
                        filterFunction={(loan: CalculationLoanType) => loan.tags.includes(LoanTags.ACTIVE)}
                        showLookThrough={false}
                    />
                </>
            );

        // FUM / Investor/Unit Capital
        case 'contractualFum':
            return (
                <>
                    <FUMDrillDown fund={fund} period={period}/>
                </>
            );

        // CONTRACTUAL BANK FACILITIES
        case 'contractualFacilities':
        case 'contractualExternalFacilities':
        case 'contractualInternalFacilities':
            return (
                <FacilitiesDrillDown
                    fund={fund}
                    totalFacilities={column.contractualFacilities}
                    externalFacilities={column.contractualExternalFacilities}
                    internalFacilities={column.contractualInternalFacilities}
                />
            );

        // CONTRACTUAL CAPITAL AUM
        case 'contractualCapitalAUM':
            return (
                <DrillDownsSimple
                    data={[
                        {
                            label: 'Investor Unit Capital',
                            value: column.contractualFum,
                        },
                        {
                            label: 'Bank Facilities',
                            value: column.contractualFacilities,
                        },
                        {
                            label: 'Total AUM',
                            value: column.contractualCapitalAUM,
                            textColor: 'white',
                            sx: {bgcolor: 'primary.light'}
                        }
                    ]}
                />
            );

        // SURPLUS DEFICIT
        case 'surplusDeficit':
            return (
                <DrillDownsSimple
                    data={[
                        {
                            label: 'Total AUM',
                            value: column.contractualCapitalAUM,
                        },
                        {
                            label: 'Contractual Total Commitments',
                            value: -column.contractualTotalCommitment,
                        },
                        {
                            label: 'Contracted Surplus/(Deficit)',
                            value: column.surplusDeficit,
                            textColor: 'white',
                            sx: {bgcolor: 'primary.light'}
                        },
                    ]}
                />

            );

        // UNDRAWN CRE CTC
        case 'undrawnCRECTC':
            return (
                <>
                    <LoansDrilldownTable
                        title={'Undrawn CRE CTC'}
                        period={period}
                        fund={fund}
                        additionalColumns={[
                            {
                                field: 'undrawn',
                                headerName: 'Drawn',
                                ...valueCurrency,
                                width: 120
                            }
                        ]}
                        filterFunction={(loan: CalculationLoanType) => ((loan.tags.includes(LoanTags.CRE_CTC) || (loan.tags.includes(LoanTags.NEW_LOAN) && loan.investmentType === InvestmentType.REAL_ESTATE)) && loan.updatedUndrawn !== 0)}
                        showLookThrough={false}
                    />
                </>
            );

        // FUND FINANCE
        case 'fundFinance':
            return (
                <FundFinanceDrilldown
                    unusedFF={column.unusedFF} fundFinanceTotal={column.fundFinance}
                    fundFinances={period.fundFinances} manualFF={column.manualFF}
                />
            );

        // SUM OF UNDRAWN
        case 'sumUndrawn':
            return (
                <DrillDownsSimple
                    data={[
                        {
                            label: 'Undrawn CRE CTC',
                            value: column.undrawnCRECTC
                        },
                        {
                            label: 'Forecast Undrawn Fund Finance',
                            value: column.fundFinance
                        },
                        {
                            label: 'Sum Forecasted Undrawn',
                            value: column.sumUndrawn,
                            textColor: 'white',
                            sx: {bgcolor: 'primary.light'}
                        }
                    ]}
                />
            );

        case 'adjContSurplusDeficit':
            return (
                <>
                    <DrillDownsSimple
                        data={[
                            {
                                label: 'Contracted Surplus/(Deficit)',
                                value: column.surplusDeficit
                            },
                            {
                                label: 'Sum of Forecasted Undrawn',
                                value: column.sumUndrawn
                            },
                            {
                                label: 'Adjusted Contractual Surplus/(Deficit)',
                                value: column.adjContSurplusDeficit,
                                textColor: 'white',
                                sx: {bgcolor: 'primary.light'}
                            }
                        ]}
                    />
                </>
            )

        // NEW LOANS
        case 'newCommitments':
            return (
                <>
                    <LoansDrilldownTable
                        title={'New Loans'}
                        period={period}
                        fund={fund}
                        additionalColumns={[
                            {
                                field: 'drawn',
                                headerName: 'Draw @ Close',
                                ...valueCurrency,
                                width: 120
                            },
                            {
                                field: 'value',
                                headerName: 'Commitment',
                                ...valueCurrency,
                                width: 120
                            }
                        ]}
                        filterFunction={(loan: CalculationLoanType) => (loan.tags.includes(LoanTags.NEW_LOAN))}
                        showLookThrough={false}
                    />
                </>
            );

        // SUBSCRIPTIONS
        case 'subscriptions':
            return (
                <>
                    <CapitalDrillDownTable
                        title={'Subscriptions'}
                        data={period.capital.reduce((subscriptions: Array<Capital>, capital) => {
                            if (capital.transactionType === CapitalAction.SUBSCRIPTION) {
                                subscriptions.push(capital);
                            }
                            return subscriptions;
                        }, [])}
                    />
                </>
            );

        // REDEMPTIONS
        case 'redemptions':
            return (
                <>
                    <CapitalDrillDownTable
                        title={'Redemptions'}
                        data={period.capital.reduce((subscriptions: Array<Capital>, capital) => {
                            if (capital.transactionType === CapitalAction.REDEMPTION) {
                                subscriptions.push(capital);
                            }

                            return subscriptions;
                        }, [])}
                    />
                </>
            );

        // REPAYMENTS
        case 'repayments':
            return (
                <RepaymentsDrilldown period={period} fund={fund}/>
            );

        // INCREASE BANK FACILITIES
        case 'increaseBkFacilities':
            return (
                <>
                    <CapitalDrillDownTable
                        title={'Increased Bank Facilities'}
                        data={period.capital.reduce((facility: Array<Capital>, capital) => {
                            if (capital.transactionType === CapitalAction.COMMITMENT || capital.transactionType === CapitalAction.CANCELLATION) {
                                facility.push(capital);
                            }
                            return facility;
                        }, [])}
                    />
                </>
            );

        // ADJUSTMENTS
        case 'adjustments':
            return (
                <>
                    <AdjustmentsDrillDown period={period} fund={fund} column={column}/>
                </>
            );

        // RUNNING ADJUSTMENTS
        case 'runningAdjustments':
            return (
                <>
                    <PriorAdjustmentsDrilldown data={data} column={column.label} periodType={column.type}
                                               adjustmentTotal={column.runningAdjustments}/>
                </>
            );

        // ADJUSTED SURPLUS DEFICIT
        case 'adjustedSurplusDeficit':
            return (
                <>
                    <DrillDownsSimple
                        data={[
                            {
                                label: 'Contracted Surplus/(Deficit)',
                                value: column.surplusDeficit,
                                textColor: 'white',
                                sx: {bgcolor: 'primary.light'}
                            },
                            {
                                label: 'Sum of Forecasted Adjustments',
                                value: column.adjustments
                            },
                            {
                                label: 'Total Previous Adjustments',
                                value: column.runningAdjustments
                            },
                            {
                                label: 'Adjusted Surplus/(Deficit)',
                                value: column.adjustedSurplusDeficit,
                                textColor: 'white',
                                sx: {bgcolor: 'primary.light'}
                            }
                        ]}
                    />
                </>
            )

        case 'adjClosingSurplusDeficit':
            return (
                <>
                    <DrillDownsSimple
                        data={[
                            {
                                label: 'Adjusted Contractual Surplus/(Deficit)',
                                value: column.adjContSurplusDeficit,
                            },
                            {
                                label: 'Sum of Forecasted Adjustments',
                                value: column.adjustments
                            },
                            {
                                label: 'Total Previous Adjustments',
                                value: column.runningAdjustments
                            },
                            {
                                label: 'Adjusted Closing Surplus/(Deficit)',
                                value: column.adjClosingSurplusDeficit,
                                textColor: 'white',
                                sx: {bgcolor: 'primary.light'}
                            }
                        ]}
                    />
                </>
            )

        // CLOSING DRAWN LOANS
        case 'drawn':
            return (
                <>
                    <LoansDrilldownTable
                        title={'Closing Drawn Commitments'}
                        period={period}
                        fund={fund}
                        additionalColumns={[
                            {
                                field: 'drawn',
                                headerName: 'Drawn',
                                ...valueCurrency,
                                width: 120
                            }
                        ]}
                        filterFunction={(loan: CalculationLoanType) => loan.tags.includes(LoanTags.ACTIVE) && loan.updatedDrawn !== 0}
                    />
                </>
            );

        // CLOSING UNDRAWN LOANS
        case 'undrawn':
            return (
                <>
                    <LoansDrilldownTable
                        title={'Closing Undrawn Commitments'}
                        period={period}
                        fund={fund}
                        additionalColumns={[
                            {
                                field: 'undrawn',
                                headerName: 'Undrawn',
                                ...valueCurrency,
                                width: 120
                            }
                        ]}
                        filterFunction={(loan: CalculationLoanType) => loan.tags.includes(LoanTags.ACTIVE) && loan.updatedUndrawn !== 0}
                    />
                </>
            );

        // CLOSING TOTAL COMMITMENT
        case 'totalCommitment':
            return (
                <>
                    <LoansDrilldownTable
                        title={'Closing Commitments'}
                        period={period}
                        fund={fund}
                        additionalColumns={[
                            {
                                field: 'value',
                                headerName: 'Commitment',
                                ...valueCurrency,
                                width: 120
                            }
                        ]}
                        filterFunction={(loan: CalculationLoanType) => loan.tags.includes(LoanTags.ACTIVE)}
                    />
                </>
            );

        // AVAILABLE CAPITAL
        case 'availCap':
            return (
                <DrillDownsSimple
                    data={[
                        {
                            label: 'Available Capital',
                            value: column.availCap,
                        }
                    ]}
                />
            );

        // ANALYTICS AUM
        case 'analyAUM':
            return (
                <DrillDownsSimple
                    data={[
                        {
                            label: 'Total Commitment',
                            value: column.totalCommitment,
                        },
                        {
                            label: 'Available Capital',
                            value: column.availCap,
                        },
                        {
                            label: 'AUM',
                            value: column.analyAUM,
                            textColor: 'white',
                            sx: {bgcolor: 'primary.light'}
                        },
                    ]}
                />
            );

        // RATES
        case 'wavMargin':
        case 'baseRate':
        case 'wavYield':
        case 'wavInterestRate':
            return (
                <RatesDrillDown period={period} column={column} fund={fund}/>
            );

        // WAV MATURITY
        case 'wavMaturity':
            return (
                <>
                    <LoansDrilldownTable
                        title={'Loans Maturity'}
                        period={period}
                        fund={fund}
                        additionalColumns={[
                            {
                                field: 'tenor',
                                headerName: 'Tenor',
                                valueFormatter: (params) => roundToDecimal(params.value),
                                width: 120
                            }
                        ]}
                        filterFunction={(loan: CalculationLoanType) => (loan.tags.includes(LoanTags.ACTIVE) && !['Equity', 'Other'].includes(loan.ranking))}
                    />
                </>
            )

        // DEBT SENIORITY
        case 'senior':
        case 'subordinated':
        case 'equity':
        case 'other':
        case 'capPer':
            return (
                <DebtSeniorityDrillDown period={period} column={column} avaCapToggle={avaCapToggle} fund={fund}/>
            );

        // CREDIT RATINGS
        case 'wavRating':
        case 'wavRatingValue':
        case 'investmentGrade':
        case 'subInvestmentGrade':
            return (
                <CreditRatingsDrillDown period={period} column={column} avaCapToggle={avaCapToggle} fund={fund}/>
            );

        // Exposure
        case 'exposure':
            return (
                <ExposureDrilldown column={column} avaCapToggle={avaCapToggle}/>
            );

        // ASSET
        case 'assets':
        case 'avgSize':
        case 'largestExposure':
        case 'largestExposurePer':
        case 'topTenExposure':
        case 'topTenExposurePer':
            return (
                <AssetsDrillDown period={period} column={column}/>
            )

        // DOMICILE
        case 'domicile':
            return (
                <DomicileDrillDown period={period} column={column} avaCapToggle={avaCapToggle} fund={fund}/>
            );

        // AUD
        case 'aud':
            return (
                <AudDrillDown period={period} column={column} avaCapToggle={avaCapToggle} fund={fund}/>
            );

        default:

            switch (true) {

                case /^sector/.test(row.id as string):
                    const sector = /^sector(.*)/.exec(row.id as string)![1];

                    return (
                        <IndustryDrilldown
                            id={'sector'}
                            header={'Sector'}
                            field={sector}
                            value={((avaCapToggle) ? column.analyAUM : column.totalCommitment) * (row.id ? column[row.id] : 0)}
                            period={period}
                            column={column}
                            avaCapToggle={avaCapToggle}
                            fund={fund}
                            filter={(loan: CalculationLoanType) => (loan.sector === sector)}
                        />
                    );

                case /^group/.test(row.id as string):
                    const group = /^group(.*)/.exec(row.id as string)![1];

                    return (
                        <IndustryDrilldown
                            id={'industryGroup'}
                            header={'Ind. Grp.'}
                            field={group}
                            value={((avaCapToggle) ? column.analyAUM : column.totalCommitment) * (row.id ? column[row.id] : 0)}
                            period={period}
                            column={column}
                            avaCapToggle={avaCapToggle}
                            fund={fund}
                            filter={(loan: CalculationLoanType) => (loan.industryGroup === group)}
                        />
                    );

                case /^industry/.test(row.id as string):
                    const industry = /^industry(.*)/.exec(row.id as string)![1];

                    return (
                        <IndustryDrilldown
                            id={'industry'}
                            header={'Industry'}
                            field={industry}
                            value={((avaCapToggle) ? column.analyAUM : column.totalCommitment) * (row.id ? column[row.id] : 0)}
                            period={period}
                            column={column}
                            avaCapToggle={avaCapToggle}
                            fund={fund}
                            filter={(loan: CalculationLoanType) => (loan.industry === industry)}
                        />
                    );

                default:
                    return (
                        <>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    textAlign: 'center'
                                }}
                            >
                                <Typography
                                    variant="h4"
                                >
                                    No Data Found.
                                </Typography>
                            </Box>
                        </>
                    )
            }
    }
}

export default memo(SummaryDrilldown);