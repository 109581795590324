import {useState} from 'react';
// import {addDays, checkDateSameOrBefore} from "../../utils/DateUtils";
// MUI
import {Grid, Checkbox, Container, Select, MenuItem, Box, FormControlLabel} from '@mui/material';
import {GridColDef} from "@mui/x-data-grid";
// Components
import {Page} from "../../layouts/components";
import ForecastConfigBar from "./ForecastConfigBar";
import {DataTable, valueCurrency, valueDate} from "../../components";
import {generalRow} from "../../components/DataTable/DataTableUtils";
// Store
import {useAppSelector} from "../../store/store";
import {rawPortfolioGpSelector} from "../../store/capitalBudget/selectors/portfolioSelector";
import {getForecastDate} from "../../store/capitalBudget/selectors/generalSelectors";
import {addDays, checkDateSameOrBefore} from "../../utils/DateUtils";

let columns: GridColDef[] = [
    {field: 'tranche_id', headerName: 'Tranche ID', width: 90, ...generalRow},
    {
        field: 'borrower',
        headerName: 'Borrower',
        flex: 1,
        minWidth: 300,
        ...generalRow
    },
    {
        field: 'tranche',
        headerName: 'Tranche',
        minWidth: 150,
        flex: 0.5,
        ...generalRow
    },
    {
        field: 'maturity',
        headerName: 'Maturity',
        ...valueDate,
        width: 100,
    },
    {
        field: 'amendment',
        headerName: 'Amendment',
        minWidth: 140,
        ...generalRow,
        align: 'left'
    },
    {
        field: 'adjustedMaturity',
        headerName: 'Adj. Maturity',
        ...valueDate,
        width: 140,
    },
    {
        ...valueCurrency,
        field: 'commitment',
        headerName: 'Commitment',
        width: 150,
    },
    {
        ...valueCurrency,
        field: 'commitmentAUD',
        headerName: 'Commitment (AUD)',
        width: 150,
    },
    {
        ...valueCurrency,
        field: 'drawnAUD',
        headerName: 'Drawn (AUD)',
        width: 150,
    },
    {
        ...valueCurrency,
        field: 'undrawnAUD',
        headerName: 'Undrawn (AUD)',
        width: 150,
    },
];

// Capital Budget Portfolio Page
export default function Portfolio() {
    const loading = useAppSelector(state => state.forecast.loading);
    const portfolio = useAppSelector(state => rawPortfolioGpSelector(state));
    const forecastDate = useAppSelector(state => getForecastDate(state))
    const [daysFilter, setDaysFilter] = useState<number | ''>('');
    const [amendmentFilter, setAmendmentFilter] = useState<boolean>(false);

    return (
        <Page title="Metrics - CB - Portfolio">
            <Container maxWidth={false} sx={{maxWidth: '100%', height: 1}}>
                <Grid container direction='column' sx={{height: 1}}>
                    <Grid container direction='row' justifyContent='space-between'>
                        <ForecastConfigBar title='Portfolio'/>
                    </Grid>
                    <Grid item container sx={{width: '100%', flex: 1}}>
                        <Grid item xs={12} md={12} lg={12} sx={{flex: 1}}>
                            <DataTable
                                title={'Direct Loans'}
                                columns={[...columns]}
                                data={portfolio.filter(l => {
                                    if (amendmentFilter && !l.amendment) return false;
                                    if (daysFilter === '' || !forecastDate) return true;

                                    const limit = addDays(new Date(forecastDate), daysFilter);

                                    return checkDateSameOrBefore(l.adjustedMaturity, limit)
                                })
                                }
                                loading={loading}
                                showExport={true}
                                showColumns={true}
                                sort={{field: 'adjustedMaturity', sort: 'asc'}}
                                search
                                customFilterComponent={<>
                                    <AmendmentFilter amendment={amendmentFilter} setAmendment={setAmendmentFilter} />
                                    <MaturityFilter days={daysFilter} setDays={setDaysFilter}/>
                                </>
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )
}

function AmendmentFilter({amendment, setAmendment}: {
    amendment: boolean,
    setAmendment: (amendment: boolean) => void
}) {
    return (
        <>
            <Box sx={{px: 1}}>
                <FormControlLabel
                    control={<Checkbox checked={amendment} onChange={() => setAmendment(!amendment)} color={'secondary'}/>}
                    label='Show only Adjusted:'
                    labelPlacement='start'
                />
            </Box>
        </>
    )
}

function MaturityFilter({days, setDays}: { days: number | '', setDays: (days: number | '') => void }) {
    return (
        <>
            <Box sx={{px: 1}}>
                Maturing in:
                <Select
                    value={days}
                    onChange={(e) => setDays(e.target.value as number)}
                    sx={{minWidth: 100, color: 'common.white'}}
                    label={'Days'}
                    size={"small"}
                >
                    <MenuItem value={''}>Any</MenuItem>
                    <MenuItem value={30}>30 Days</MenuItem>
                    <MenuItem value={60}>60 Days</MenuItem>
                    <MenuItem value={90}>90 Days</MenuItem>
                    <MenuItem value={180}>180 Days</MenuItem>
                </Select>
            </Box>
        </>
    )
}