import {createSelector} from "@reduxjs/toolkit";
import {addArrayValues, addValues} from "../../../../utils/mathUtil";
import {HorizontalTableColumn} from "../../../../components";
import {fCurrency} from "../../../../utils/formatNumber";
import {CellType} from "../../../../types/InputTypes";
import {ForecastPeriod} from "../../../../types/capitalBudgetTypes";
import {RootState} from "../../../store";
import {fumBalanceSelector, FundDetails, fundHolderSelector} from "../generalSelectors";
import {retrieveBankFacilitiesLimit} from "./facilitiesSelector";
import {createFilteredPortfolioBook} from "../../../../utils/CapitalBudgetUtils";
import {CapitalAction, InvestmentType, LoanTags, OtherTransactionTypes} from "../../../../types/capitalBudgetEnums";
import {checkInPeriod} from "../../../../utils/DateUtils";
import {retrieveUnusedFundFacility} from "../fundFinanceSelector";


const ForecastCapitalRows = [
    {
        id: 'openingFum',
        label: 'FUM',
        formatter: fCurrency,
        headSX: {minWidth: 200},
        type: CellType.CURRENCY,
    },
    {
        id: 'totalBankFacilities',
        label: 'Total Facilities',
        formatter: fCurrency,
        headSX: {minWidth: 200},
        type: CellType.CURRENCY,
        subRows: [
            {
                id: 'externalFacilities',
                label: 'External Facilities',
                formatter: fCurrency,
                type: CellType.CURRENCY,
            },
            {
                id: 'internalFacilities',
                label: 'Internal Facilities',
                formatter: fCurrency,
                type: CellType.CURRENCY,
            }
        ]
    },
    {
        id: 'commitments',
        label: 'Commitments in Axcess',
        formatter: fCurrency,
        headSX: {minWidth: 200},
        type: CellType.CURRENCY,
    },
    {
        id: 'surplusDeficit',
        label: 'Surplus / (Deficit)',
        formatter: fCurrency,
        type: CellType.CURRENCY,
        headSX: {
            bgcolor: 'info.lighter',
            color: 'black',
            fontWeight: 'bold',
            borderColor: 'secondary.light',
            '&:hover': {color: 'white', backgroundColor: 'secondary.light', borderColor: 'secondary.light'},
        },
        sx: {bgcolor: 'info.lighter', fontWeight: 'bold'}
    },
    {
        id: 'divider',
        label: '',
        headSX: {minWidth: 150, height: 15, bgcolor: 'common.white', borderColor: 'primary.main'},
        sx: {bgcolor: 'common.white'}
    },
    {
        id: 'adjustments',
        label: 'Adjustments',
        headSX: {minWidth: 250, bgcolor: 'primary.light', fontWeight: 'bold', borderColor: 'primary.light'},
        sx: {bgcolor: 'primary.light', fontWeight: 'bold', color: 'white', borderColor: 'primary.light'},
    },
    {
        id: 'inflows',
        label: 'Inflows',
        headSX: {minWidth: 250, bgcolor: 'primary.main', borderColor: 'primary.main'},
        sx: {bgcolor: 'primary.main'}
    },
    {
        id: 'subscriptions',
        label: 'Investor Subscriptions',
        formatter: fCurrency,
        headSX: {minWidth: 200},
        type: CellType.CURRENCY
    },
    {
        id: 'repayments',
        label: 'Repayments',
        formatter: fCurrency,
        headSX: {minWidth: 200},
        type: CellType.CURRENCY
    },
    {
        id: 'bankFacilities',
        label: 'Increased Bank Facilities',
        formatter: fCurrency,
        headSX: {minWidth: 200},
        type: CellType.CURRENCY
    },
    {
        id: 'divider',
        label: '',
        headSX: {minWidth: 150, height: 15, bgcolor: 'common.white', borderColor: 'primary.main'},
        sx: {bgcolor: 'common.white'}
    },
    {
        id: 'outflows',
        label: 'Outflows',
        headSX: {minWidth: 250, bgcolor: 'primary.main', borderColor: 'primary.main'},
        sx: {bgcolor: 'primary.main'}
    },
    {
        id: 'divider',
        label: '',
        headSX: {minWidth: 150, height: 15, bgcolor: 'common.white', borderColor: 'primary.main'},
        sx: {bgcolor: 'common.white'}
    },
    {
        id: 'redemptions',
        label: 'Investor Redemptions',
        formatter: fCurrency,
        headSX: {minWidth: 200},
        type: CellType.CURRENCY
    },
    {
        id: 'newCommitments',
        label: 'New Loan Commitments',
        formatter: fCurrency,
        headSX: {minWidth: 200},
        type: CellType.CURRENCY
    },
    {
        id: 'divider',
        label: '',
        headSX: {minWidth: 150, height: 15, bgcolor: 'common.white', borderColor: 'primary.main'},
        sx: {bgcolor: 'common.white'}
    },
    {
        id: 'adjustedSurplus',
        label: 'Adjusted Surplus / (Deficit)',
        formatter: fCurrency,
        type: CellType.CURRENCY,
        headSX: {
            bgcolor: 'info.lighter',
            color: 'black',
            fontWeight: 'bold',
            borderColor: 'secondary.light',
            '&:hover': {color: 'white', backgroundColor: 'secondary.light', borderColor: 'secondary.light'},
        },
        sx: {bgcolor: 'info.lighter', fontWeight: 'bold'}
    },
    {
        id: 'creCTCCommitments',
        label: 'Forecast CRE CTC Commitments',
        formatter: fCurrency,
        headSX: {minWidth: 200},
        type: CellType.CURRENCY
    },
    {
        id: 'unusedFundFinance',
        label: 'Forecast Undrawn Fund Finance Commitments',
        formatter: fCurrency,
        headSX: {minWidth: 200},
        type: CellType.CURRENCY
    },
    {
        id: 'adjustedClosingSurplus',
        label: 'Adjusted Closing Surplus / (Deficit)',
        formatter: fCurrency,
        type: CellType.CURRENCY,
        headSX: {
            bgcolor: 'info.lighter',
            color: 'black',
            fontWeight: 'bold',
            borderColor: 'secondary.light',
            '&:hover': {color: 'white', backgroundColor: 'secondary.light', borderColor: 'secondary.light'},
        },
        sx: {bgcolor: 'info.lighter', fontWeight: 'bold'}
    },
    {
        id: 'divider',
        label: '',
        headSX: {minWidth: 150, height: 15, bgcolor: 'common.white', borderColor: 'primary.main'},
        sx: {bgcolor: 'common.white'}
    },
    {
        id: 'fundedVia',
        label: 'Funded via:',
        headSX: {minWidth: 250, bgcolor: 'primary.light', fontWeight: 'bold', borderColor: 'primary.main'},
        sx: {bgcolor: 'primary.light', fontWeight: 'bold', color: 'white'},
    },
    {
        id: 'cre',
        label: 'CRE',
        formatter: fCurrency,
        headSX: {minWidth: 200},
        type: CellType.CURRENCY
    },
    {
        id: 'revolvers',
        label: 'Revolvers',
        formatter: fCurrency,
        headSX: {minWidth: 200},
        type: CellType.CURRENCY
    },
    {
        id: 'capex',
        label: 'Capex',
        formatter: fCurrency,
        headSX: {minWidth: 200},
        type: CellType.CURRENCY
    },
    {
        id: 'other',
        label: 'Other',
        formatter: fCurrency,
        headSX: {minWidth: 200},
        type: CellType.CURRENCY
    },
    {
        id: 'fundedTotal',
        label: 'Total',
        formatter: fCurrency,
        type: CellType.CURRENCY,
        headSX: {
            bgcolor: 'info.lighter',
            color: 'black',
            fontWeight: 'bold',
            borderColor: 'secondary.light',
            '&:hover': {color: 'white', backgroundColor: 'secondary.light', borderColor: 'secondary.light'},
        },
        sx: {bgcolor: 'info.lighter', fontWeight: 'bold'}
    },
]

export interface forecastCapitalColumn extends HorizontalTableColumn {
    openingFum: number,
    totalBankFacilities: number,
    externalFacilities: number,
    internalFacilities: number,
    commitments: number,
    surplusDeficit: number,
    subscriptions: number,

    // USED FOR REPAYMENTS DRILL DOWN
    contractualRepayments: number,
    extensions: number,
    earlyRepayments: number,
    sellRepay: number,
    offsetExtension: number,
    offsetEarly: number,
    offsetSellRepay: number,
    transfers: number,
    transfersOffset: number,

    repayments: number,

    bankFacilities: number,
    redemptions: number,
    newCommitments: number,
    adjustedSurplus: number,
    creCTCCommitments: number,
    unusedFundFinance: number,
    adjustedClosingSurplus: number,

    cre: number,
    revolvers: number,
    capex: number,
    other: number,
    fundedTotal: number,
}


export const forecastCapitalDataSelector = createSelector(
    (state: RootState) => state.capitalBudget.capitalBudget.budget,
    fundHolderSelector,
    fumBalanceSelector,
    retrieveBankFacilitiesLimit,
    retrieveUnusedFundFacility,
    (budget, fund, balance, bankFacilitiesLimit, unusedFundFacility) => {
        if (!budget) return null

        const base = handleForecastCapitalBase(budget.base, balance, bankFacilitiesLimit, fund);

        // CALCULATE FORECAST CAPITAL WEEKLY
        let weeklyRunningFUM = base.openingFum;
        let weeklyRunningBankFacility = bankFacilitiesLimit.total;
        let weeklyRunningBankExternal = bankFacilitiesLimit.external;
        let weeklyRunningCommitments = base.commitments;
        let weeklyRunningFundFacility = -unusedFundFacility;
        const weeks: Array<forecastCapitalColumn> = budget.weeks.map(w => {
            const result = handleForecastCapitalPeriod(w, weeklyRunningFUM, weeklyRunningBankFacility, weeklyRunningBankExternal, bankFacilitiesLimit.internal, weeklyRunningCommitments, weeklyRunningFundFacility, fund);
            weeklyRunningFUM = addArrayValues([result.openingFum, result.subscriptions, result.redemptions]);
            weeklyRunningBankFacility = addValues(weeklyRunningBankFacility, result.bankFacilities);
            weeklyRunningBankExternal = addValues(weeklyRunningBankExternal, result.bankFacilities);
            weeklyRunningCommitments = addArrayValues([weeklyRunningCommitments, result.repayments, result.newCommitments]);
            weeklyRunningFundFacility = result.unusedFundFinance;
            // Manual FF defines the manually adjusted portion of the FF Undrawn
            result.manualFF = addValues(result.unusedFundFinance, unusedFundFacility);

            return result;
        })

        // CALCULATE FORECAST CAPITAL MONTHLY
        let monthlyRunningFUM = base.openingFum;
        let monthlyRunningBankFacility = base.totalBankFacilities;
        let monthlyRunningBankExternal = bankFacilitiesLimit.external;
        let monthlyRunningCommitments = base.commitments;
        let monthlyRunningFundFacility = -unusedFundFacility;
        const months: Array<forecastCapitalColumn> = budget.months.map(m => {
            const result = handleForecastCapitalPeriod(m, monthlyRunningFUM, monthlyRunningBankFacility, monthlyRunningBankExternal, bankFacilitiesLimit.internal, monthlyRunningCommitments, monthlyRunningFundFacility, fund);
            monthlyRunningFUM = addArrayValues([result.openingFum, result.subscriptions, result.redemptions]);
            monthlyRunningBankFacility = addValues(monthlyRunningBankFacility, result.bankFacilities);
            monthlyRunningBankExternal = addValues(monthlyRunningBankExternal, result.bankFacilities);
            monthlyRunningCommitments = addArrayValues([monthlyRunningCommitments, result.repayments, result.newCommitments]);
            monthlyRunningFundFacility = result.unusedFundFinance;
            // Manual FF defines the manually adjusted portion of the FF Undrawn
            result.manualFF = addValues(result.unusedFundFinance, unusedFundFacility);

            return result;
        })

        return {
            base,
            weeks,
            months
        }
    }
)

export const forecastCapitalReport = createSelector(
    forecastCapitalDataSelector,
    (forecastCapitalData) => {
        if (!forecastCapitalData) return {
            rows: ForecastCapitalRows,
            data: []
        }

        let {
            base,
            weeks,
            months
        } = forecastCapitalData;

        weeks[0].sx = {borderLeft: 2, borderLeftColor: 'primary.main'};
        months[0].sx = {borderLeft: 2, borderLeftColor: 'primary.main'};

        return {
            data: [base, ...weeks, ...months],
            rows: ForecastCapitalRows
        }
    }
)

// Creates Base level Capital Calculations not effected by forecasted scenarios
function handleForecastCapitalBase(base: ForecastPeriod, startingFum: number, bankFacilities: {
    external: number,
    internal: number,
    total: number
}, fund: FundDetails | null): forecastCapitalColumn {
    let results = {
        openingFum: startingFum,
        totalBankFacilities: bankFacilities.total,
        externalFacilities: bankFacilities.external,
        internalFacilities: bankFacilities.internal,
        commitments: 0,
        surplusDeficit: 0,
        subscriptions: 0,
        repayments: 0,
        bankFacilities: 0,
        redemptions: 0,
        newCommitments: 0,
        adjustedSurplus: 0,
        creCTCCommitments: 0,
        unusedFundFinance: 0,
        adjustedClosingSurplus: 0,

        cre: 0,
        revolvers: 0,
        capex: 0,
        other: 0,
        fundedTotal: 0,
    };

    // FILTER LOANS FOR THOSE IN SELECTED FUND
    const filteredBook = createFilteredPortfolioBook(base.book, fund, true, false);

    filteredBook.forEach(loan => {
        results.commitments = addValues(results.commitments, -loan.value);

        if (loan.investmentType !== InvestmentType.REAL_ESTATE) {
            switch (loan.trancheType) {
                case 'Revolving':
                    results.revolvers = addValues(results.revolvers, loan.undrawn);
                    break;

                case 'Capex':
                    results.capex = addValues(results.capex, loan.undrawn);
                    break;

                default:
                    results.other = addValues(results.other, loan.undrawn);
                    break;
            }
        } else {
            results.cre = addValues(results.cre, loan.undrawn);
        }
    })

    base.otherTransactions.forEach(ot => {
        if ((!fund || (fund && fund.label === ot.fund)) && (ot.transactionType === OtherTransactionTypes.FUM_ADJUSTMENT)) {
            results.openingFum = addValues(results.openingFum, ot.amount);
        }
    })


    const surplusDeficit = addArrayValues([results.openingFum, results.totalBankFacilities, results.commitments])
    const fundedTotal = addArrayValues([results.cre, results.revolvers, results.capex, results.other])
    return {
        ...results,
        surplusDeficit,

        contractualRepayments: 0,
        extensions: 0,
        earlyRepayments: 0,
        sellRepay: 0,
        offsetExtension: 0,
        offsetEarly: 0,
        offsetSellRepay: 0,
        transfers: 0,
        transfersOffset: 0,

        fundedTotal,
        label: 'Contractual',
        labelTwo: base.labelTwo,
        tooltip: `Contractual Position at ${base.labelTwo}`,

        sx: {borderRight: 4, borderRightColor: 'primary.main', minWidth: 150, bgcolor: 'info.lighter'}
    }
}

// Calculates Forecast Capital Period taking into account forecasted scenarios
function handleForecastCapitalPeriod(period: ForecastPeriod, runningFum: number, bankFacTotal: number, bankFacExternal: number, bankFacInternal: number, commitments: number, unusedFundFinance: number, fund: FundDetails | null): forecastCapitalColumn {
    let results = {
        openingFum: runningFum,
        totalBankFacilities: bankFacTotal,
        externalFacilities: bankFacExternal,
        internalFacilities: bankFacInternal,
        commitments: commitments,
        surplusDeficit: 0,
        subscriptions: 0,
        repayments: 0,
        bankFacilities: 0,
        redemptions: 0,
        newCommitments: 0,
        adjustedSurplus: 0,
        creCTCCommitments: 0,
        unusedFundFinance,
        adjustedClosingSurplus: 0,

        cre: 0,
        revolvers: 0,
        capex: 0,
        other: 0,
        fundedTotal: 0,
    };

    let contractualRepayments = 0;
    let extensions = 0;
    let earlyRepayments = 0;
    let sellRepay = 0;
    let offsetExtension = 0;
    let offsetEarly = 0;
    let offsetSellRepay = 0;
    let transfers = 0; // Transfers IN and OUT
    let transfersOffset = 0; // Transfers Offset IN and OUT

    // Handle Book
    const filteredBook = createFilteredPortfolioBook(period.book, fund, false, false);

    filteredBook.forEach(loan => {
        // Used as holder for CTC CRE
        let ctcCRE = 0;
        loan.tags.forEach(tag => {

            switch (tag) {
                case LoanTags.REPAYMENT:
                    contractualRepayments = addValues(contractualRepayments, loan.value);
                    break;

                case LoanTags.EXTENSION:
                    extensions = addValues(extensions, loan.value);
                    break;

                case LoanTags.EARLY_REPAYMENT:
                    earlyRepayments = addValues(earlyRepayments, loan.value);
                    break;

                case LoanTags.SELLDOWN:
                    const selldown = (loan.selldowns || []).reduce((total, s) => {
                        if (checkInPeriod(s.date, period)) {
                            let value = s.amount;
                            if (fund && fund.holdMap) {
                                const portion = fund.holdMap.get(loan.fund);
                                if (portion) {
                                    value = s.amount * portion;
                                }
                            }
                            total = addValues(total, value);
                        }
                        return total;
                    }, 0);
                    sellRepay = addValues(sellRepay, selldown || 0);
                    break;

                case LoanTags.OFFSET_EXTENSION:
                    offsetExtension = addValues(offsetExtension, -loan.value);
                    break;

                case LoanTags.OFFSET_EARLY:
                    offsetEarly = addValues(offsetEarly, -loan.value);
                    break;

                case LoanTags.OFFSET_SELLDOWN:
                    const selldownOffset = (loan.selldowns || []).reduce((total, s) => {
                        let value = s.amount;
                        if (fund && fund.holdMap) {
                            const portion = fund.holdMap.get(loan.fund);
                            if (portion) {
                                value = s.amount * portion;
                            }
                        }
                        total = addValues(total, value);

                        return total;
                    }, 0);
                    offsetSellRepay = addValues(offsetSellRepay, -selldownOffset);
                    break;

                case LoanTags.TRANSFER_IN:
                    const transferIn = (loan.transfersIn || []).reduce((total, t) => {
                        if (checkInPeriod(t.transferDate, period)) {
                            let value = t.amount;
                            if (fund && fund.holdMap) {
                                const portion = fund.holdMap.get(loan.fund);
                                if (portion) {
                                    value = t.amount * portion;
                                }
                            }
                            total = addValues(total, value);
                        }
                        return total;
                    }, 0);
                    transfers = addValues(transfers, -transferIn);
                    break;

                case LoanTags.TRANSFER_OUT:
                    const transferOut = (loan.transfersOut || []).reduce((total, t) => {
                        if (checkInPeriod(t.transferDate, period)) {
                            let value = t.amount;
                            if (fund && fund.holdMap) {
                                const portion = fund.holdMap.get(loan.fund);
                                if (portion) {
                                    value = t.amount * portion;
                                }
                            }
                            total = addValues(total, value);
                        }
                        return total;
                    }, 0);
                    transfers = addValues(transfers, transferOut);
                    break;

                case LoanTags.OFFSET_TRANSFER_IN:
                    const transfersInOffset = (loan.transfersIn || []).reduce((total, t) => {
                        let value = t.amount;
                        if (fund && fund.holdMap) {
                            const portion = fund.holdMap.get(loan.fund);
                            if (portion) {
                                value = t.amount * portion;
                            }
                        }
                        total = addValues(total, value);
                        return total;
                    }, 0);
                    transfersOffset = addValues(transfersOffset, transfersInOffset)
                    break;

                case LoanTags.OFFSET_TRANSFER_OUT:
                    const transfersOutOffset = (loan.transfersOut || []).reduce((total, t) => {
                        let value = t.amount;
                        if (fund && fund.holdMap) {
                            const portion = fund.holdMap.get(loan.fund);
                            if (portion) {
                                value = t.amount * portion;
                            }
                        }
                        total = addValues(total, value);
                        return total;
                    }, 0);
                    transfersOffset = addValues(transfersOffset, -transfersOutOffset)

                    break;

                case LoanTags.NEW_LOAN:
                    results.newCommitments = addValues(results.newCommitments, -loan.value);
                    if (loan.investmentType === InvestmentType.REAL_ESTATE) {
                        // if (period.label === 'Current Week') console.log(loan.tags)
                        // results.creCTCCommitments = addValues(results.creCTCCommitments, (loan.updatedUndrawn || 0))
                        if (ctcCRE === 0) ctcCRE = loan.updatedUndrawn || 0;
                    }
                    break;

                case LoanTags.CRE_CTC:
                    // results.creCTCCommitments = addValues(results.creCTCCommitments, (loan.updatedUndrawn || 0))
                    // if (period.label === 'Current Week') console.log(loan.tags)
                    if (ctcCRE === 0) ctcCRE = loan.updatedUndrawn || 0;
                    break;

                default:
                    break;
            }
        })

        results.creCTCCommitments = addValues(results.creCTCCommitments, ctcCRE)
    })

    // if (period.label === 'Current Week') console.log(period.label, results.creCTCCommitments)

    // Include Other Transactions
    period.otherTransactions.forEach(ot => {
        if (ot.capital && (!fund || (fund.label === ot.fund))) {
            if (ot.transactionType === OtherTransactionTypes.FUND_FACILITIES_UNDRAWN) {
                results.unusedFundFinance = addValues(results.unusedFundFinance, -ot.amount);
            } else if (ot.transactionType === OtherTransactionTypes.FUND_TRANSFER) {
                transfers = addValues(transfers, ot.amount);
            }
        }
    })

    period.capital.forEach(c => {
        let amount = 0;
        if (fund) {
            if (fund.label === c.fund) {
                amount = c.amount;
            } else {
                const portion = fund.inheritance.get(c.fund);
                if (portion) {
                    amount = c.amount * portion;
                }
            }
        } else {
            amount = c.amount
        }
        switch (c.transactionType) {
            case CapitalAction.SUBSCRIPTION:
                results.subscriptions = addValues(results.subscriptions, amount);
                break;

            case CapitalAction.REDEMPTION:
                results.redemptions = addValues(results.redemptions, amount);
                break;

            case CapitalAction.COMMITMENT:
                if (!fund || (fund.label === c.fund)) {
                    results.bankFacilities = addValues(results.bankFacilities, c.amount);
                }
                break;

            case CapitalAction.CANCELLATION:
                if (!fund || (fund.label === c.fund)) {
                    results.bankFacilities = addValues(results.bankFacilities, c.amount);
                }
                break;

            default:
                break;
        }
    })

    // Surplus / (Deficit)
    const surplusDeficit = addArrayValues([results.openingFum, results.totalBankFacilities, results.commitments]);

    // Repayments
    const repayments = addArrayValues([
        contractualRepayments,
        extensions,
        earlyRepayments,
        sellRepay,
        offsetExtension,
        offsetEarly,
        offsetSellRepay,
        transfers,
        transfersOffset
    ]);

    const adjustedSurplus = addArrayValues([
        surplusDeficit,
        results.subscriptions,
        repayments,
        results.bankFacilities,
        results.redemptions,
        results.newCommitments,
    ]);

    const adjustedClosingSurplus = addArrayValues([
        adjustedSurplus,
        results.creCTCCommitments,
        results.unusedFundFinance,
    ])

    return {
        ...results,
        surplusDeficit,

        contractualRepayments,
        extensions,
        earlyRepayments,
        sellRepay,
        offsetExtension,
        offsetEarly,
        offsetSellRepay,
        transfers,
        transfersOffset,

        repayments,

        adjustedSurplus,
        adjustedClosingSurplus,

        label: period.label,
        labelTwo: period.labelTwo,
        tooltip: period.tooltip,
    }
}